import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faNewspaper, faPerson, faQuestion, faToolbox, faTools, faTv, faVideo } from '@fortawesome/free-solid-svg-icons';
import LoadingBar from 'react-top-loading-bar';



const Navbar = () => {

  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const location = useLocation();
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    // Close the mobile navigation menu on route change
    setIsMobileNavOpen(false);
  }, [location]);

  const toggleMobileNav = () => {
    setIsMobileNavOpen((prevState) => !prevState);
  };

  const handleNavItemClick = (e) => {
    const targetLink = e.currentTarget;
    const navDropdown = targetLink.parentNode.querySelector('.nav-dropdown');
    if (navDropdown) {
      e.preventDefault();
      navDropdown.style.display = navDropdown.style.display === 'block' ? 'none' : 'block';
      
    }

  };
  const handleNavItemClickk = (e) => {
    const targetLink = e.currentTarget;
    const navDropdown = targetLink.parentNode.querySelector('.nav-dropdown');
    if (navDropdown) {
      e.preventDefault();
      navDropdown.style.display = navDropdown.style.display === 'block' ? 'none' : 'block';
    }
    setLoadingProgress(30); // Set an initial progress value
    setTimeout(() => {
      setLoadingProgress(100); // Complete loading
    }, 500); // Simulate loading completion time
  };

  const handleDropdownLinkClick = () => {
    setLoadingProgress(30); // Set an initial progress value
    setTimeout(() => {
      setLoadingProgress(100); // Complete loading
    }, 500); // Simulate loading completion time
  };
    
  
    Aos.init();

    return (
      <>


      <LoadingBar
        color="#007bff"
        progress={loadingProgress}
        onLoaderFinished={() => setLoadingProgress(0)}
      />
          <div className='nav-contact-mail'>
            <p className='mail-contact'><b>E-Mail</b> : Gh.industry.pk@gmail.com | <b>WhatsApp</b> :+92 301 6367000 | <b>Call</b> : +92-300-7448688 ,
+92-0301-6367000</p>
          </div>
      <section className="navigation" data-aos="fade-down" data-aos-delay="800">
        <div className="nav-container">
          <div className="brand">
            <Link to="/">G.H Industry</Link>
          </div>
          <nav>
            <div className={`nav-mobile ${isMobileNavOpen ? 'acctive' : ''}`} onClick={toggleMobileNav}>
              <span className="hamburger"></span>
            </div>
            <ul className={`nav-list ${isMobileNavOpen ? 'acctive' : ''}`}>
              <li>
                <Link to="/" onClick={handleNavItemClickk} className='nav-hed-font'>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" onClick={handleNavItemClick} className='nav-hed-font'>
                  Company
                </Link>
                <ul className="nav-dropdown">
                  <li>
                    <Link to="/about" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faAddressBook} className="i" />&nbsp;  About G.H </Link>
                  
                  </li>
                  <li>
                    <Link to="/news" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faNewspaper} className="i" />&nbsp; G.H News</Link>
                  </li>
                  <li>
                    <Link to="/videos" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faVideo} className="i" />&nbsp; G.H Videos</Link>
                  </li>
                  <li>
                    <Link to="/social-media" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faTv} className="i" />&nbsp; Social Media</Link>
                  </li>
                </ul>
              </li>
              
              <li>
                <Link href="#!" onClick={handleNavItemClick} className='nav-hed-font'>
                  Production Center
                </Link>
                <ul className="nav-dropdown">
                  <li className='hed-feed'>
                    <Link to="/feed-cleaning-system" onClick={handleDropdownLinkClick}>Feed Equipments :</Link>
                  </li>
                  <li>
                    <Link to="/feed-cleaning-system" onClick={handleDropdownLinkClick}>Feed Cleaning System</Link>
                  </li>
                  <li>
                    <Link to="/feed-crushing-system" onClick={handleDropdownLinkClick}>Feed Crushing System</Link>
                  </li>
                  <li>
                    <Link to="/feed-mixing-system" onClick={handleDropdownLinkClick}>Feed Mixing System</Link>
                  </li>
                  <li>
                    <Link to="/batching-system" onClick={handleDropdownLinkClick}>Batching System</Link>
                  </li>
                  <li>
                    <Link to="/feed-pelletizing-system" onClick={handleDropdownLinkClick}>Feed Pelletizing System</Link>
                  </li>
                  <li>
                    <Link to="/feed-cooling-machine" onClick={handleDropdownLinkClick}>Feed Cooling Machine</Link>
                  </li>
                  <li>
                    <Link to="packaing-system" onClick={handleDropdownLinkClick}>Packaging System </Link>
                  </li>
                  <li>
                    <Link to="/conveyor-system" onClick={handleDropdownLinkClick}>Conveyor System</Link>
                  </li>
                  <li>
                    <Link to="/auxiliary-equipment" onClick={handleDropdownLinkClick}>Auxiliary Equipment</Link>
                  </li>
                  <li className='hed-feed'>
                    <Link to="/wood-crusher" onClick={handleDropdownLinkClick}>Wood Equipments :</Link>
                  </li>
                  <li>
                    <Link to="/wood-crusher" onClick={handleDropdownLinkClick}>Wood Crusher</Link>
                  </li>
                  <li>
                    <Link to="/wood-dryer" onClick={handleDropdownLinkClick}>Wood Dryer</Link>
                  </li>
                  <li>
                    <Link to="/wood-pellet-mill" onClick={handleDropdownLinkClick}>Wood Pellet Mill</Link>
                  </li>
                  <li>
                    <Link to="/pellet-cooler" onClick={handleDropdownLinkClick}>Pellet Cooler</Link>
                  </li>
                  <li>
                    <Link to="/packaing-system" onClick={handleDropdownLinkClick}>Packaging System</Link>
                  </li>
                  <li>
                    <Link to="/conveyor-system" onClick={handleDropdownLinkClick}>Conveyor System</Link>
                  </li>
                  <li>
                    <Link to="/auxiliary-equipment" onClick={handleDropdownLinkClick}>Auxiliary Equipment</Link>
                  </li>
                </ul>
              </li>
              <li>
              </li>
              <li>
                <Link href="#!" onClick={handleNavItemClick} className='nav-hed-font'>
                Service
                </Link>
                <ul className="nav-dropdown">
                  <li>
                    <Link to="/service" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faTools} className="i" />&nbsp; Service Introduction</Link>
                  </li>
                  <li>
                    <Link to="/parts" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faToolbox} className="i" />&nbsp; Genuine Spare Parts</Link>
                  </li>
                  <li>
                    <Link to="/customer-guide" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faPerson} className="i" />&nbsp; Customer Guide</Link>
                  </li>
                  <li>
                    <Link to="/faq-page" onClick={handleDropdownLinkClick}><FontAwesomeIcon icon={faQuestion} className="i" />&nbsp; FAQ</Link>
                  </li>
                </ul>
                
              </li>
              
              <li>
                <Link to="/contact" onClick={handleNavItemClickk} className='nav-hed-font'>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      </>
      
  );
};

export default Navbar;
