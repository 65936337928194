import React from 'react';
import './News.css';
import { Header } from './Header';

export const News = () => {
  document.title="G.H - News";
    return (
      <>
      <Header head={'News'}/>
      <main class="main">
      <section class="sectionnnn banner banner-section">
      
        <div class="container banner-column">
          <div class="banner-inner">
            <h1 class="heading-xl" data-aos="fade-up" data-aos-delay="500">News Center</h1>
            <p class="paragraph" data-aos="fade-up" data-aos-delay="500">
            The G.H. Industry Update Column is your entry point to the most recent events. Explore the world as we introduce initiatives that challenge conventions. Experience how we influence conversations and trends in the media. Engage in campaigns that inspire innovation and pique curiosity. This article provides reliable information on G.H. Industry's excellence and assures its validity. Stay connected, involved, and a part of our dynamic story as we shape the future.h us.
            </p>
            {/* <button class="btn btn-darken btn-inline" data-aos="fade-up" data-aos-delay="500">
              Read More
              <FontAwesomeIcon icon={faArrowRight} className="i" />
            </button> */}
          </div>
          {/* <div class="banner-links"data-aos="fade-left" data-aos-delay="1500">
            <a href="#" title="">
              <FontAwesomeIcon icon={faFacebookF} className="i" fade />
            </a>
            <a href="#" title="">
              <FontAwesomeIcon icon={faInstagram} className="i" fade />
            </a>
            <a href="#" title="">
              <FontAwesomeIcon icon={faTwitter} className="i" fade />
            </a>
            <a href="#" title="">
              <FontAwesomeIcon icon={faYoutube} className="i" fade />
            </a>
          </div> */}
          
        </div>
      </section>
      
    </main>
    </>
  )
}
