import React , {useEffect} from 'react';
import './About.css';
import { Header } from './Header';
import mock from './Images/mock.png';
import mock2 from './Images/mock2.jpg';
import ceo from './Images/ceo.png';
import director from './Images/director.jpg';
import { Link } from 'react-router-dom';



export const About = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  document.title="G.H - About";
  return (
    <>
    
    <Header head={'About'}/>
    <div class="we-are-block">

  <div id="about-us-section" data-aos="fade-left" data-aos-delay="800">

    <div class="about-us-image">

      <img src={mock} width="808" height="200" alt="Lobby Image"/>

    </div>

    <div class="about-us-info">

      <h2>Company Intro</h2>

      <p>GH Industry was established in 1985 by CEO Asghar Mughal. It covers a site area of 9800 square feet and specializes in manufacturing production lines and related machinery suitable for use in poultry feed, stock feed, and feed additive factories. Leveraging this expertise, GH Industry has experienced continuous growth year after year. We are committed to being at the forefront of innovation in our field, constantly seeking ways to enhance our products to meet evolving customer demands. Meticulous attention is dedicated to the design and production of our lines, ensuring that our products are of the utmost quality while remaining competitively priced.</p>

      <Link to="/contact" title="Contact Us Button" >CONTACT US</Link>

    </div>
    

  </div>

  <div id="history-section" data-aos="fade-right" data-aos-delay="800">

    <div class="history-image">

      <img src={mock2} width="951" height="471" alt="Building Pic"/>

    </div>

    <div class="history-info">

      <h2>Company Object</h2>

      <p>
We primarily provide comprehensive solutions for animal feed projects, specializing in Poultry, Cattle, and Fish Feed Mill Plants, offering complete accessories and on-site installation services to buyers. Our commitment to constant change management and the drive to maintain a competitive edge extend not only locally but also on a global scale. This commitment leads us to incorporate the world's most advanced and sophisticated technologies. The driving force behind our research and development is to create distinctive product lines that are convenient, reliable, and durable in functionality, all while being efficient in terms of cost and performance.</p>

      <a href="/service" title="History Button">SERVICES</a>

    </div>

  </div>

  

</div>

<section class="about-section section" data-aos="fade-up" data-aos-delay="1000">
  <div class="container">
    <div class="row">
      <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
        <div class="inner-column">
          <div class="sec-title">
            <span class="title">CEO's Message About Growth</span>
            <h2>International Market Success & Vision and Commitment to Growth</h2>
          </div>
          <div class="text">We are proud and happy that we were able to effectively grow into the international market. We are happy to report that our mills have been successfully installed and are running in a number of nations, including Oman, Kuwait, Qatar, Nepal, and Malaysia. Our success is evidence of our constant dedication to upholding the strictest worldwide safety and standard guidelines.

Our mills are exemplary examples of dependability and efficiency in Oman, Kuwait, Qatar, Nepal, and Malaysia. They are not just pieces of equipment; rather, they are the product of our commitment to perfection. Each mill has been painstakingly set up and calibrated to run without a hitch, assuring peak efficiency and productivity. Additionally, due to our steadfast adherence to worldwide safety and quality standards,that the highest level of accuracy is used in our mill operations, and that strict regulations are followed to ensure both employee and environmental safety.



We are steadfast in our objective to uphold the high standards that have helped us get this far as we make our mark on the world stage. Our capacity to adapt, our innovative attitude, and our unwavering dedication to providing top-notch goods and services are evident in our success in these various areas. While keeping our guiding principles of excellence, quality, and safety, we look forward to further extending our reach and leaving our mark on new horizons.
</div>
          <div class="text">
          We have made a name for ourselves as a vibrant, forward-thinking industry via our quest of excellence and growth. Our mission is to lead the market through unwavering commitment to quality and customer service. The maximization of exports serves as our guiding philosophy, and we do this while making certain that our clients receive enticing returns on their investments.

Recognizing and appreciating the qualities and performances of our associates is essential to our success. We passionately believe in rewarding ability, effort, and dedication and in creating a culture where each person's particular contributions are respected and appropriately recognized.By doing this, we make a substantial contribution to the expansion and advancement of our sector, laying the foundation for a more promising and prosperous future.

We are unwavering in our commitment to environmental stewardship even as we pursue expansion and success. Our products have earned a reputation for being low-emission and environmentally friendly. Our production practices demonstrate that we are committed to conserving the environment as ethical business citizens.

We consider our social obligations to be important in every facet of our business activities. We fervently embrace our duty as a responsible business organization, whether it is ensuring customer pleasure, advancing the industry, or protecting the environment. We are on a path of constant improvement because of our unrelenting commitment to market leadership, superior quality, and first-class customer service.

Our progress is greatly aided by the network of engineers and mentors we have built. We are committed to growing this network and fostering a collaborative atmosphere where ideas can blossom.
          </div>
          <div class="btn-box">
            <Link to="/faq-page" class="theme-btn btn-style-one">FAQ</Link>
          </div>
        </div>
      </div>

      <div class="image-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column wow fadeInLeft">
          <div class="author-desc">
            <h2>Asghar Mughal</h2>
            <span> <b> CEO</b></span>
          </div>
          <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="Rahul Kumar Yadav" src={ceo} alt=""/></a></figure>

        </div>
      </div>
      

    </div>
    <div class="row">
      <div class="content-column col-lg-6 col-md-12 col-sm-12 order-2">
        <div class="inner-column">
          <div class="sec-title">
            <span class="title">Director's Message</span>
            <h2>Charting the Future: A Message from the Director of GH Industry</h2>
          </div>
          <div class="text">Greetings, cherished partners and stakeholders.<br/><br/>

As the Director of GH Industry, a business that has been committed to excellence and innovation since its founding in 1985, I am honored to address you. I consider it an honor to serve as the leader of this outstanding organization, and I am forward to share our future plans.



GH Industry, which has a site area of 9800 square feet and specializes in building production lines and machinery for factories that make feed additives, stock feed, and poultry feed, has carved out a prestigious niche for itself in the industry over the years. Our CEO, Asghar Mughal, has guided us with vision and is responsible for our steady growth and success. This accomplishment is evidence of our ongoing dedication to meeting and exceeding the changing needs of our esteemed customers.
We at GH Industry are aware that innovation is the foundation of our success. Pushing the limits of what is possible in our sector is something that our team of hardworking professionals is constantly working toward. We view change as an opportunity to improve our offerings, making sure they are of the best caliber while yet being reasonably priced. This commitment to thorough design and production distinguishes us in the field.
</div>
          <div class="text">
          Future-focused or not, our dedication to innovation is unwavering. To keep our products and solutions at the top of the market, we will keep making investments in R&D, investigating cutting-edge technology, and working with specialists in the field. We think that by doing this, we not only solidify our position as an industry leader but also give our clients the tools they need to prosper in a constantly changing environment.



Without the confidence and assistance of our esteemed partners and stakeholders, our success would not be feasible. We are grateful for your trust in us and dedicated to upholding the greatest standards of honesty, openness, and client satisfaction.
In closing, I would like to thank our hardworking team, whose dedication and enthusiasm fuel our ongoing success, as well as our patrons and partners for their unwavering support. Together, we will continue to forge ahead in terms of innovation, quality, and customer satisfaction, writing the success story of the GH Industry.
<br/><br/>
We appreciate your participation in our journey.<br/><br/>

Sincerely,<br/>

(Hassan Asghar)<br/>

Director , GH Industry

          </div>
          <div class="btn-box">
            <Link to="/faq-page" class="theme-btn btn-style-one">FAQ</Link>
          </div>
        </div>
      </div>

      <div class="image-column col-lg-6 col-md-12 col-sm-12">
        <div class="inner-column wow fadeInLeft">
          <div class="author-desc">
            <h2>Hassan Asghar</h2>
            <span> <b> DIRECTOR</b></span>
          </div>
          <figure class="image-1"><a href="#" class="lightbox-image" data-fancybox="images"><img title="Rahul Kumar Yadav" src={director} alt=""/></a></figure>

        </div>
      </div>
      

    </div>
    <div class="sec-title">
      <span class="title">Our Future Goal</span>
      <h2>Our Mission & Vision</h2>
    </div>
    <div class="text">
    GH Industry has proudly retained ISO quality certification since its establishment, a monument to our unrelenting dedication to upholding high standards. Our commitment extends beyond simply fulfilling criteria; it also includes upholding the values of environmental stewardship and energy efficiency.

We have purposefully reduced noise and sound vibrations as part of our pursuit of excellence. This was accomplished by using a sophisticated suspension system and carefully choosing the materials, with a preference for heavier metal parts. These actions not only improve the efficiency of our equipment but also create a peaceful and calmer workplace.


    </div>
    <div class="text">
    Our power supply motors also meet the highest industry standards for quality. Each motor is the result of careful engineering and design, demonstrating our commitment to dependability, effectiveness, and durability. Our dedication to quality permeates every aspect of our business, ensuring that every product that leaves our facilities bears witness to our unwavering pursuit of perfection.

With the incorporation of ISO quality certification, energy-saving techniques, and ecologically friendly solutions, GH sector is a leader in our sector for innovation and responsibility. Our efforts to uphold and surpass these standards are evidence of our dedication to constantly provide the greatest service to our clients while upholding our commitment to a sustainable and responsible future.
    </div>
    <div class="text">
    Our goal is to establish market leadership at the local, national, and global levels through ongoing product innovation. In order to increase client happiness, we strive to improve the quality of our equipment while reducing expenses. GH Industry is dedicated to preserving a clear competitive advantage by early adoption of quickly emerging technologies and large investments in human resource training.

Our unrelenting quest of quality motivates us to provide our customers with unmatched goods and services at market-competitive prices. We are aware that remaining ahead of the competition in a dynamic business environment needs us to react quickly and successfully. This philosophy serves as our compass as we work to surpass customers' expectations and create brand-new standards for the sector.


    </div>
    <div class="text">
    GH Industry is prepared to lead, inspire, and redefine standards by putting a strong emphasis on innovation, quality, and customer-centricity. This will help us maintain our position as a reliable market leader both locally and internationally.
    </div>
    <div class="text">

      In the end, I would say keep visiting our website and enjoy the quality content.
    </div>
  </div>
</section>



</>
  )
}
