import React ,{useRef, useEffect}from 'react'
import './Quote.css'
import { Header } from './Header';
import emailjs from '@emailjs/browser';


export const Quote = () => {
  const form = useRef();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  document.title="G.H - Get Quote";

  const sendEmail = (e) => {
    e.preventDefault();
  
    emailjs.sendForm('service_4kbhaxe', 'template_pmc5xtu', form.current, 'fw0b2vik-v5Xv5mfd')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      let text = document.getElementById('text-form-2')
      text.innerHTML = "Message Has Been Send";
  };
  return (
    <>
    <Header head={'Get Quote'}/>
    <div className='bdody'>
    <div class="contaiiiner">

<span className='insthead'>INSTRUCTIONS :</span>
    <span class="inst">
      Please be as specific as possible when describing your needs, and G.H.'s consultants will respond to you right away: <br/><br/><br/>

      ◉ Which capacity can satisfy your demand? (Key idea)<br/><br/>

      ◉ What sort of starting material and end product are you expecting to have? (A good solution starts with the right product and material)<br/><br/>

      ◉ When should the project begin to operate? (Key information for project planning A-Z)<br/><br/>
      ◉ A budget for purchasing machinery? (Key details for the appropriate model)<br/><br/>

      ◉ things on which you truly concentrate. (Special service provided by our project consultant)</span>
  


    <h2>Get Quote</h2>
      <form ref={form} onSubmit={sendEmail}>
    <div class="row100">
      <div class="col">
        <div class="inputBox">
          <input type="text" name="user_name" required="required"/>
          <span class="text">Name</span>
          <span class="line"></span>
        </div>
      </div>
      <div class="col">
        <div class="inputBox">
          <input type="email" name="user_email" required="required"/>
          <span class="text">E-Mail</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="row100">
      <div class="col">
        <div class="inputBox">
          <input type="phone" name="user_tel" required="required"/>
          <span class="text">Tel</span>
          <span class="line"></span>
        </div>
      </div>
      <div class="col">
        <div class="inputBox">
          <input type="text" name="user_company" required="required"/>
          <span class="text">Company</span>
          <span class="line"></span>
        </div>
      </div>
      
    </div>
    <div class="row100">
      <div class="col">
      <div class="inputBox">
          <input type="text" name="user_country" required="required"/>
          <span class="text">Country</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="row100">
      <div class="col">
        <div class="inputBox textarea">
          <textarea required="required" name='user_msg'></textarea>
          <span class="text">Type your message Here...</span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="row100">
      <div class="col">
<p id='text-form-2' style={{color: 'green'}}><b></b></p>
        <input type="submit" value="Send Now"/>
      </div>
    </div>
  </form>
  </div>
  </div>
    </>
  )
}


