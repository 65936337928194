import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'


import p55 from './Products/55p.jpg'
import { ProductSeriesWood } from './ProductSeriesWood'



export const PelletCooler = () => {
  const productList = [
    {
      Pname: "SKLN Series Counterflow Cooler",
      Pcap: "3-5T/H",
      Mpow: "0.75kw",
      img: p55,
    },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Wood Equioments"}/>
    <ProductSeriesWood />
    <Products

    heading={'Pellet Cooler'}
    desc={`Introducing the Pellet Cooler from G.H. Industry, the crucial advancement in pellet production. Our Pellet Cooler is expertly engineered to quickly lower the temperature of recently manufactured pellets, maintaining their quality and lengthening their lifespan.

    The G.H Industry Pellet Cooler was created with accuracy and ingenuity to guarantee that your pellets preserve their integrity and nutritional content. Our cooler extends the shelf life of your pellets and avoids moisture buildup, ensuring they reach your consumers in the best possible shape.
    
    Our Pellet Cooler is an essential part of your operation whether you're making biomass pellets, animal feed, or any other type of pelletized material. It integrates smoothly into your production process, increasing productivity and helping to ensure consistent pellet quality.
    
    `}
    imgg={'https://images.unsplash.com/photo-1634788906402-3796dabc53e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1429&q=80'}
    products={productList}
    />
    </>
  )
}
