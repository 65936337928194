import React from 'react';
import './Service.css';
import { Header } from './Header';
import mac1 from './Images/mac1.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTools, faGears ,faShieldHeart, faVolumeControlPhone, faFileContract, faSitemap, faTrain, faHandFist, faPerson, faPersonRifle, faToolbox, faPeopleArrows, faBook, faFeed} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faMeetup, faServicestack } from '@fortawesome/free-brands-svg-icons';

export const Service = () => {
  document.title="G.H - Service";
  return (
<>
    <Header head={'Services'} />

    <div id="wrapper">
  <section class="services">
    <div class="container">
      <div class="row">
        <div class="row text-center">
          <h1><span>services</span></h1>
          <p className='p'>
            JUST CHECK OUT ALL OF THE SERVICE LISTS BELOW TO GET A GENERAL IDEA OF WHAT WE ARE CAPABLE OF DELIVERING.
          </p>
        </div>
        <div class="col-md-6">
          <div class="box-a">
            <div class="col-md-3">
              
              <FontAwesomeIcon icon={faCheckCircle} className="ii" />
            </div>
            <div class="col-md-9">
              <h3>Accurate Control</h3>
              <p className='p'>
              Profits can be anticipated with accurate cost accounting control and scientifically calculated predicted returns on investment.We have a professional project manager to track the entire process, so that you can avoid risks and increase the project's value. This includes equipment selection and program planning in the early stages, equipment capacity optimization and improvement in the middle stages, and after-sales service in the late stages.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="box-b">
            <div class="col-md-9">
              <h3>Customize Service</h3>
              <p className='p'>
              We will develop different process plans in accordance with the actual demands of the customer, evaluate them, select the most reasonable solution, and carry out individualized customisation.Our services include field surveys of the topography and environmental conditions, the design of manufacturing lines, the testing of raw materials, cost analysis, project investment budgets, the installation and debugging of equipment, and more.
              </p>
            </div>
            <div class="col-md-3">

              <FontAwesomeIcon icon={faServicestack} className="ii" />

            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="box-a">
            <div class="col-md-3">
           
              <FontAwesomeIcon icon={faSitemap} className="ii" />

            </div>
            <div class="col-md-9">
              <h3>Site Inspection</h3>
              <p className='p'>
              Focus on more than 90 countries and regions in the world of successful cases, and you can see a single machine or even every step of the entire production line, look at the profitability and operation of almost a thousand clients, examine the entire service system from the design team to the maintenance team, and see the project's investment value being realized.

              </p>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="box-b">
            <div class="col-md-9">
              <h3>Training support</h3>
              <p className='p'>
              We work with a group of highly qualified experts with diverse relevant specialties in order to better satisfy the project's unique requirements and goals. These professionals are entrusted with leading painstakingly planned training sessions that are specially designed to address the project's particular requirements.This monitoring procedure makes sure that the project is moving along as intended 

              </p>
            </div>
            <div class="col-md-3">

              <FontAwesomeIcon icon={faHandFist} className="ii" />

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>




<div className="custtom-section">
      <div className="custom-images">
        <img src={mac1} alt="Image 1" className="custom-image left-image" />

      </div>
      <div className="custom-content">
        <div className="centered-content">
          <h2 className="custom-title">Do you need help and service?</h2>
          <p className="custom-text p">When engaging with pivotal projects, G.H. Machinery goes above and beyond to provide exceptional pre-sale evaluations. This ensures that customers gain access to an array of exclusive benefits, designed to enhance their experience and project outcomes. <br/> Clients will benefit from a range of specialized services, including a complimentary special coating service that enhances the longevity and performance of equipment. Our commitment extends further with on-site services, ensuring a seamless setup and operation process. For critical projects, we offer extended on-site assistance, providing ongoing support that fosters worry-free operations. <br/> <span className='helpp'>Free technical enquiries with our experts.

Free repair service during warranty period.
Free special services for all key construction projects.
Free training of operators and repairs staff.
Free maintenance service tracking (customer provide resources)
Special Services for Key Projects </span></p>
          <button className="custom-button">Learn More</button>
        </div>
      </div>
      <div className="custom-images">

        <img src={mac1} alt="Image 2" className="custom-image right-image" />
      </div>
    </div>


    <section className='sectionnn1'>

  <div class="row">
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faPerson} className="i" />

        </div>
        <h3>Customer Focus</h3>
        <p>
        We understand that every consumer who buys G.H. items wants to make money and get something out of the deal. In order to help the customer grasp the use, upkeep, building, and repair of our machines, our service personnel should possess a broad range of service skills.

        </p>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faToolbox} className="ii" />

        </div>
        <h3>Maintenance Priority</h3>
        <p>
        In order to ensure that equipment operates normally, we address any on-site issues as soon as they arise. This is done in accordance with the guiding philosophy of "Troubleshooting before Accountability."
        </p>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faShieldHeart} className="ii" />

        </div>
        <h3>Honesty and Trustworthiness</h3>
        <p>
        Integrity and Sincerity
When providing services, we prioritize honesty and cannot defraud clients based on the truth.

        </p>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faPeopleArrows} className="ii" />

        </div>
        <h3>Meeting Demands</h3>
        <p>
        We pay special attention to and accommodate clients' legitimate requests during services to allay their concerns. To enhance their experiences, we should offer higher-quality services to our most important clients.

        </p>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faBook} className="ii" />

        </div>
        <h3>Results-oriented</h3>
        <p>
        The service professionals have always believed that it is their responsibility to successfully resolve issues and that they should work toward results.

        </p>
      </div>
    </div>
    <div class="column">
      <div class="card">
        <div class="icon-wrapper">
        <FontAwesomeIcon icon={faServicestack} className="ii" />

        </div>
        <h3>Timely Feedback</h3>
        <p>
        We must provide feedback to the market service technicians for assistance when it comes to client requests and serious complaints from customers that cannot be resolved quickly in a service center.
        </p>
      </div>
    </div>
  </div>
</section>
</>
  )
}
