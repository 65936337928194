import React from 'react';
import './Videos.css';
import { Header } from './Header';


export const Videos = () => {
  document.title="G.H - Videos";
  return (
    <>
    <Header head={'Videos'} />
    <h1 className='vid-heading'>G.H Industry Videos</h1>
    <div class="gallery">
  <div class="single-video">
    <figure>
      <iframe src="https://www.youtube.com/embed/xb6vbfKI0cY" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
     
    </figure>
  </div>
  <div class="single-video">
    <figure>
      <iframe src="https://www.youtube.com/embed/Z4LzTUflfxY" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </figure>
  </div>
  <div class="single-video">
    <figure>
      <iframe src="https://youtube.com/embed/pAMbJwWGQ-w" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </figure>
  </div>
  <div class="single-video">
    <figure>
      <iframe src="https://youtube.com/embed/EpLGkCS_nPE" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </figure>
  </div>
  <div class="single-video">
    <figure>
      <iframe src="https://youtube.com/embed/D7cT2KQj-cQ" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </figure>
  </div>
</div>

</>

  )
}
