import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'


import p34 from './Products/34p.jpg'
import p35 from './Products/35p.jpg'


export const FeedCoolingMachine = () => {
  const productList = [
    {
      Pname: "SKLN Series Counterflow Cooler",
      Pcap: "3-5T/H",
      Mpow: "0.75kw",
      img: p34,
    },
    {
      Pname: "Stainless Steel Pellet Cooler",
      Pcap: "3-5T/H",
      Mpow: "0.75kw",
      img: p35,
    },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Feed Cooling Machine'}
    desc={`Introducing the Feed Cooling Machine from G.H. Industry, the best way to improve the quality and longevity of your animal feed. Our Feed Cooling Machine makes sure that your freshly processed feed preserves its nutritional integrity by being precisely and innovatively designed.

    A key part of your feed processing line is our feed cooling machine. It effectively lowers the feed pellets' temperature, minimizing moisture buildup and protecting the vital nutrients. This results in increased feed stability and shelf life, which ultimately improves the health and productivity of your animals.
    
    G.H Industry's Feed Cooling Machine meets your needs whether you run a small farm or a large feed manufacturing facility. Our products seamlessly integrate into your production process, contributing to effective production with an emphasis on performance and dependability.`}
    imgg={'https://images.unsplash.com/photo-1548151002-671039d69247?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'}
    products={productList}
    />
    </>
  )
}
