import {React , useState} from 'react';
import './Parts.css';
import { Header } from './Header';

import prt1 from './Parts/1part.jpg'

import prt7 from './Parts/7part.jpg'

import prt12 from './Parts/12part.jpg'
import prt13 from './Parts/13part.jpg'

import prt18 from './Parts/18part.jpg'

import prt20 from './Parts/20part.jpg'

import prt22 from './Parts/22part.jpg'
import prt23 from './Parts/23part.jpg'
import prt24 from './Parts/24part.jpg'
import prt25 from './Parts/25part.jpg'
import prt26 from './Parts/26part.jpg'

import prt28 from './Parts/28part.jpg'
import prt29 from './Parts/29part.jpg'
import prt30 from './Parts/30part.jpg'

import prt32 from './Parts/32part.jpg'
import prt33 from './Parts/33part.jpg'
import prt34 from './Parts/34part.jpg'
import prt35 from './Parts/35part.jpg'



const ProductCard = ({ name, price, image }) => {
    const [isPopupActive, setPopupActive] = useState(false);
  
    // const togglePopup = () => {
    //   setPopupActive(!isPopupActive);
    // };
  
    document.title="G.H - Parts";
    return (
        
        
      <div className="product" data-aos="fade-up" data-aos-delay="800">
        <div className="product-card">
          <h2 className="name">{name}</h2>
        
          {/* <a className="popup-btn" href='#'>
            Get Quote
          </a> */}
          <img src={image} className="product-img" alt="" />
        </div>
        {isPopupActive && (
          <div className="popup-view">
            <div className="popup-card">
              <a >
                <i className="fas fa-times close-btn"></i>
              </a>
              <div className="product-img">
                <img src={image} alt="" />
              </div>
              <div className="info">
                <h2>
                  {name}
                  <br />
                  <span>Product Description</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <span className="price">${price}</span>
                <a href="#" className="add-cart-btn">
                  Add to Cart
                </a>
                <a href="#" className="add-wish">
                  Add to Wishlist
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
        
    );
  };
export const Parts = () => {
  return (
    <>
    <Header head={'Parts'}/>
    <div class="custom-section">
    <img class="custom-bg-image" src="https://images.unsplash.com/photo-1567093322503-341d262ad8f9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" alt="Background Image"/>
    <div class="custom-overlay"></div>
    <div class="cusstom-text">
      <h1>ACCESSORIES</h1>
      <p>In order to maintain a high level of work efficiency and a lengthy service cycle, genuine parts must be used. Our accessories are meticulously tempered, made with high-quality materials using advanced production technologies under tight quality control.Top grade is defined as having a stable chemical composition, good wear resistance, high strength, high temperature resistance, corrosion resistance, and durability.</p>
    </div>
  </div>
    
    <div className="containeer">
    <ProductCard
      name="Pellet Mill-Ring Die"
      price=""
      image={prt1}
    />
    {/* <ProductCard
      name="Xiomi 7 Smart Watch"
      price="100.00"
      image={prt2}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt3}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt4}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt5}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt6}
    /> */}
    <ProductCard
      name="Cutting Blade"
      price="250.00"
      image={prt7}
    />
    {/* <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt8}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt9}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt10}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt11}
    /> */}
    <ProductCard
      name="Grinder Rotor"
      price="250.00"
      image={prt12}
    />
    <ProductCard
      name="Grinder Seve"
      price="250.00"
      image={prt13}
    />
    {/* <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt14}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt15}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt16}
    />
    <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt17}
    /> */}
    <ProductCard
      name="Grinder Pin Shaft"
      price="250.00"
      image={prt18}
    />
    {/* <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt19}
    /> */}
    <ProductCard
      name="Grinder Screen   Hold Down"
      price="250.00"
      image={prt20}
    />
    {/* <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt21}
    /> */}
    <ProductCard
      name="Mixer Chain"
      price="250.00"
      image={prt22}
    />
    <ProductCard
      name="Mixer Cylinder"
      price="250.00"
      image={prt23}
    />
    <ProductCard
      name="Mixer Double Shaft Mixer"
      price="250.00"
      image={prt24}
    />
    <ProductCard
      name="Mixer Nozzle"
      price="250.00"
      image={prt25}
    />
    <ProductCard
      name="Mixer SJHS Series Rotor"
      price="250.00"
      image={prt26}
    />
    {/* <ProductCard
      name="Cannon Camera"
      price="250.00"
      image={prt27}
    /> */}
    <ProductCard
      name="Mixer Sprocket"
      price="250.00"
      image={prt28}
    />
    <ProductCard
      name="Pellet Mill Alloy Cutting Blade"
      price="250.00"
      image={prt29}
    />
    <ProductCard
      name="Pellet Mill Driving Rim"
      price="250.00"
      image={prt30}
    />
    <ProductCard
      name="Mixer RIbbon"
      price="250.00"
      image={prt35}
    />
    <ProductCard
      name="Pellet Mill Main Shaft"
      price="250.00"
      image={prt32}
    />
    <ProductCard
      name="Pellet Mill Press Roller"
      price="250.00"
      image={prt33}
    />
    <ProductCard
      name="Pellet Mill Roller Shell"
      price="250.00"
      image={prt34}
    />


  </div>
  </>
  )
}
