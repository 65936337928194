import React from 'react';
import './CustomerGuide.css'; // Make sure to import your stylesheet
import { Header } from './Header';

const GridItem = ({ title, text, backgroundImage, link }) => {
  const gridStyle = {
    background: `url("${backgroundImage}") no-repeat center center`,
    backgroundSize: 'cover',
    filter: 'contrast(120%)',
    
  };
  document.title="G.H - Customer Guide";

  return (
    <>
    
    <div className="o--grid__item" style={gridStyle}>
      <div className="o--grid__content black-over">
        <div className="o--grid__title">{title}</div>
        <div className="o--grid__text">{text}</div>
        {/* <a className="o--grid__link" href={link}></a> */}
      </div>
    </div>
    </>
  );
};

const GridContainer = () => (
  
  <div className="o--grid">
    <GridItem
      title="How To Build A High-Quality Feed Project?"
      text="What components are necessary today to create a project for high-quality feed? G.H Industry provides the following summaries for you based on years of expertise building entire sets of projects:

      1- Considerable planning for the factory environment
      
      
      
      2- Specific quality requirements for process equipment
      
      
      
      3- Strict supervision and oversight of engineering construction nodes
      
      
      
      4- Internationally recognized quality assurance system
      
      
      
      5- Continuous smart factory innovation
      
      
      
      6- Durable and trustworthy tools"
      backgroundImage="https://images.unsplash.com/photo-1627920768537-8f71c0c8dc44?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1633&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
    <GridItem
      title="How Should Small And Medium-Sized Feed Mills Deal With The Downturn In Pig Prices?"
      text="Live pig prices have fallen over the past few months. Many small and medium-sized pig feed mill plants have seen a severe decline in sales. We ought to approach it logically, manage it scientifically, make suitable plans, and react logically. This is the best option. How can small and medium feed mills respond if pig prices are low?

      1- Preparing premium piglet feed
      
      2- Prepare quality sow feed
      
      3- Decrease fatalities
      
      4- Feeding pigs' guts"
      backgroundImage="https://images.unsplash.com/photo-1580682777666-24a7b3024e24?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
    <GridItem
      title="How To Choose The Processing Parameters Of The 300,000 Laying Hens Farm?"
      text="The elements of the laying hen farm itself are significantly different from how the chicken feed pellet plant processes the laying hen feed pellets.

      1- Laying Hen Feed Pellet Processing Plant Flow Diagram
      
      2- Layer farm self-proportioning procedure
      
      Crushing and mixing are common steps in the creation of the farmer's own materials for laying hens.
      
      (a) Crushing of raw materials  (b) Raw material mixing "
      backgroundImage="https://images.unsplash.com/photo-1588597989061-b60ad0eefdbf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
    <GridItem
      title="Different Animal Feed, Different Feed Granulation Process"
      text="More and more facilities are using ring die feed pellet mills due to the growing popularity and use of pellet feed in the livestock and poultry, aquatic products, and other breeding industries, as well as emerging industries like compound fertilizers, hops, chrysanthemums, wood chips, peanut shells, and cottonseed meal. Users have various needs for pellet feed due to varied formulas and regional variations. For the pellet feed it produces, each feed maker demands great pelleting efficiency and exceptional pellet quality."
      backgroundImage="https://images.unsplash.com/photo-1614574047706-271e6b7086bb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="Raw Material Prices Skyrocketing, How Should Animal Feed Plants Respond"
      text="Although the cost of feed raw materials has increased significantly, the cost of feed can only marginally increase. Animal pellet feed factories are experiencing declining profitability. The breeding industry's earnings has also decreased. Both the market for feed and the willingness to eat are smaller. Feed businesses have successfully cut expenses in response to rising raw material costs by modifying feed formulae."
      backgroundImage="https://images.unsplash.com/photo-1607048550383-fcabcc31dc8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="Very Detailed Feed Formula For Laying Hens"
      text="Chickens bred solely for the production of eggs are referred to as laying hens. In contrast to rearing meat chickens, the primary goal of keeping hens is to enhance egg quality and maintain or grow egg output. The primary source of income for laying hens is eggs.

      1. Chicken feed (days 1 to 60)
      
      2. Feed for young chickens (61-120 days)
      
      3. Feed for laying hens"
      backgroundImage="https://images.unsplash.com/photo-1661921364121-26825beef6e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="How To Raise Freshwater Shrimp?"
      text="Freshwater shrimps are delicious on the table, many people love to eat, but how to raise freshwater shrimps, many people, especially novice farmers, are not very clear. In fact, according to the following method, you can basically understand how to raise freshwater shrimp.
      
      1- Freshwater Shrimp Farming
2- Pond Requirements
3- Pond Preparation
4- Fertilization and Cultivation
5- Shrimp Seedling Selection"
      backgroundImage="https://images.unsplash.com/photo-1625943553852-781c6dd46faa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1045&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="How To Get More Benefits In The Late Laying Period Of Laying Hens?"
      text="The robust egg market and high egg prices in late 2019 presented a lucrative opportunity for laying hen profitability against the backdrop of factors like African swine disease and market swings. The best use of this potential required methods to raise egg production rates and quality. Even while higher peak egg output was associated with better earnings, older hens showed decreased performance, which led to uneven eggs and lower production rates. The management of post-peak decline and the cost-effective culling of low-performing hens identified as the main obstacles to maintaining profitability."
      backgroundImage="https://images.unsplash.com/photo-1498654077810-12c21d4d6dc3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="For All The Newcomers:What Knowledge Do You Need To Learn To Engage In The Chicken Industry?"
      text="What education do I require to start raising chickens? Many consumers who are interested in starting a business in poultry farming are concerned about this issue. There are a lot of things to learn even though there isn't much scientific research being done on chicken farming. Farming chickens is not a low-level, unskilled occupation. Actually, it is quite sophisticated. Without technology, there is no way to create money. "
      backgroundImage="https://images.unsplash.com/photo-1604272451012-c1928e953064?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />
        <GridItem
      title="Beef Cattle Breeding Feed Formula Technology"
      text="To ensure optimal growth and the welfare of the herd, modern beef cow breeding relies on sophisticated feed formulation. Computational models help with precise ingredient selection to ensure individualized meals that take into account variables like age and weight. Precision in nutrition balancing is improved by data-driven insights, and targeted production goals are addressed by smart supplementation. Utilizing cutting-edge technologies, real-time modifications optimize feed ratios, fostering immunological health, muscular growth, and reproductive success. This strategy is further enhanced by sustainable practices and environmental considerations, which leads to healthier cattle and increased operational effectiveness."
      backgroundImage="https://images.unsplash.com/photo-1596733430284-f7437764b1a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
      link="http://kaustubhmenon.com/grid-layout-2/"
    />


    {/* Add more GridItem components here for each grid */}
  </div>
);

export const CustomerGuide = () => (
  <>
  <Header head={'Customer Guide'}/>
  <h1 className='hjk'>Raw Materials & Formula</h1>
  <div>

    <GridContainer />
  </div>
  <h1 className='hjk'> Video Guide</h1>


  <div class="grid-container">
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/xb6vbfKI0cY" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed//urXeU6qtyTo" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/x1vJBMeVfiM" frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/pAMbJwWGQ-w" frameborder="0" allowfullscreen></iframe>
  </div>
</div>
  </>
);
