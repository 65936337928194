import React from 'react';
import './SectionForMobile.css';

 const SectionForMobile = () => {
  return (
    <div className="s0ection-container">
    <div className="o0verlay">
      <div className="c0ontent">
        <h1 className="h0eading">Animal Feed Pellet Machine Series</h1>
        <p className="d0escription">SZLH series feed pellet machine is mainly suitable for processing animal feed pellets such as livestock, poultry, ruminant, and aquatic products</p>

<a class="button-50" role="button" value="More" href='/feed-crushing-system'>More</a>

        <h1 className="h0eading">Feed Hammer Mill Machine Series</h1>
        <p className="d0escription">The SFSP series hammer crusher is mainly suitable for crushing corn, sorghum, wheat, crushed cakes and other raw grain materials.</p>
        <a class="button-50" role="button" value="More" href='/feed-cooling-machine'>More</a>

        <h1 className="h0eading">Animal Feed Mixing Machine Series</h1>
        <p className="d0escription">Widely used in animal feed processing, brewing, chemical and biological industry. Patented ventilating from shaft end system, output of small aperture material increase by more than 25%.</p>
        <a class="button-50" role="button" value="More" href='/feed-mixing-system'>More</a>

      </div>
    </div>
  </div>
  )
}

export default SectionForMobile;
