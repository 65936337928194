import React, { useState } from 'react';
import './Faq.css'; // Make sure to have the corresponding CSS file

function Faq() {
  const [expandedItem, setExpandedItem] = useState(null);

  const toggleAccordion = (index) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      setExpandedItem(index);
    }
  };

  const accordionData = [
    {
      question: 'Why Is The Feed Mildew?',
      answer: `1. Reason:

      (1) Wet feed, inappropriate storage, and rain
      
      (2) In winter, pay attention to corn stored in tall silos since there is a significant (more than 5°C) ....... Visit FAQPage `,
    },
    {
      question: 'Poor Packaging Quality, Wrong Packaging Labels?',
      answer: `1. Reason:

      (1) Poor package quality and lax quality control (outsourcing of weaving, inner membrane)
      
      (2) The packer shows negligence ....... Visit FAQPage `,
    },
    {
      question: 'What Makes A Chicken Binder Great?',
      answer: 'If a chicken binder is made with high quality, they help a lot with preventing the food from heat and moisture. It has other great factors as well as reducing energy costs, protecting the pellets from being ruined during transport, and reducing the process of pellet crumbling.',
    },
    {
      question: 'Why Add Salt To The Chicken Diet?',
      answer: `Salt can improve the palatability of diets, promote appetite, and increase feed utilization. If there is insufficient salt in the chicken's diet, it will cause loss of appetite and digestive disorders, slow growth of the chicks, pecking addiction, and the weight and egg weight of the laying hens will decrease, and the egg production rate will decrease. The salt content of the chicken's diet should be 0.37% to 0.5%, and the dosage must be accurate. When formulating diets, the salt content of animal feed should be considered, and then the amount of supplementary salt should be determined.

      For example, when salted  ....... Visit FAQPage `,
    },
    {
      question: 'What Are The Manifestations Of Poor Performance Of Feed Products?',
      answer: `(1) Small feed intake, diarrhea, periodic feather pecking, feed, paralysis, high feed to meat ratio; 　　

      (2) The pellets have high powder content, high pellet hardness, dry finished products, and uneven pellet sizes.`,
    },
  ];

  return (
    <div className="containerr">
      <h2 className='faq-h'>Frequently Asked Questions (FAQ)</h2>
      <div className="a0ccordion" data-aos="fade-up" data-aos-delay="500">
        {accordionData.map((item, index) => (
          <div className="accordion-item" key={index}>
            <button
              id={`accordion-button-${index}`}
              aria-expanded={expandedItem === index ? 'true' : 'false'}
              onClick={() => toggleAccordion(index)}
            >
              <p className="accordion-title">{item.question}</p>
              <span className="icon" aria-hidden="true"></span>
            </button>
            <div
              className="accordion-content"
              style={{
                opacity: expandedItem === index ? 1 : 0,
                maxHeight: expandedItem === index ? '9em' : 0,
              }}
            >
              <p>{item.answer}</p>
            </div>
          </div>
        ))}
      <h2 className='faq-h' style={{marginTop : "5rem"}}>Video Guide</h2> 
      <div class="grid-container">
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/xb6vbfKI0cY" title='1' frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed//urXeU6qtyTo"  title='2' frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/x1vJBMeVfiM"  title='3' frameborder="0" allowfullscreen></iframe>
  </div>
  <div class="grid-item">
    <iframe src="https://www.youtube.com/embed/pAMbJwWGQ-w" title='4' frameborder="0" allowfullscreen></iframe>
  </div>
</div>
      </div>
    </div>
  );
}

export default Faq;
