import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'



import p36 from './Products/36p.jpg'


export const PackaingSystem = () => {
  const productList = [
    {
      Pname: "DCS Series Automatic Packing Scale",
      Pcap: "6-8 BAGS/MIN",
      Mpow: "3kw",
      img: p36,
    }
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Packaing System'}
    desc={`Introducing the G.H. Industry Packaging System, the last step in ensuring that your feed products are delivered to consumers in perfect condition. Our Packaging System is created with extreme attention to detail in order to protect the quality of your feed while boosting the reputation of your company.



    The best option for precise and effective packaging of feed items is our packaging system. Our cutting-edge machinery assures precise measurement and secure sealing, limiting the risk of contamination and spoilage, whether it's pellets, grains, or powdered supplements.
    
    
    
    At G.H Industry, we recognize that packaging is a representation of your dedication to excellence and more than just a practical consideration. Our packaging system enhances the presentation and shelf appeal of your items while also streamlining your manufacturing process.
    `}
    imgg={'https://images.unsplash.com/photo-1449247666642-264389f5f5b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80'}
    products={productList}
    />
    </>
  )
}
