import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'


import p48 from './Products/48p.jpg'
import p49 from './Products/49p.jpg'
import p50 from './Products/50p.jpg'
import p51 from './Products/51p.jpg'
import p52 from './Products/52p.jpg'
import p53 from './Products/53p.jpg'
import p54 from './Products/54p.jpg'
import { ProductSeriesWood } from './ProductSeriesWood'


export const WoodPelletMill = () => {
  const productList = [
    {
      Pname: "1-10TPH Straw Pellet Mill",
      Pcap: "1-10T/H",
      Mpow: "37-280kw",
      Pdes: "RICHI straw pellet mill can used for production the different size straw pellet,The straw pellet is a new kind of environmentally friendly combustion energy for heating brolier and power plant, also can as bedding for horses.",
      img: p48,
    },
    {
      Pname: "MZLH-858 Grass Pellet Machine",
      Pcap: "3-4.5T/H",
      Mpow: "280kw",
      img: p49,
    },
    {
        Pname: "MZLH-768 Wood Pellet machine",
        Pcap: "2.5-4T/H",
        Mpow: "250kw",
        img: p50,
      },
      {
        Pname: "MZLH-520 Grass Pellet Mill",
        Pcap: "2-2.5T/H",
        Mpow: "132kw",
        img: p51,
      },
      {
        Pname: "MZLH-420 Sawdust Pellet Mill",
        Pcap: "1-1.2T/H",
        Mpow: "90kw",
        img: p52,
      },
      {
        Pname: "MZLH-350 Biomass Pellet Mill ",
        Pcap: "500-700KG/H",
        Mpow: "55kw",
        img: p53,
      },
      {
        Pname: "MZLH-320 Wood Pellet Mill",
        Pcap: "300-400KG/H",
        Mpow: "37kw",
        img: p54,
      },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Wood Equioments"}/>
    <ProductSeriesWood />
    <Products

    heading={'Wood Pellet Mill'}
    desc={`The MZLH Series Wood Pellet Mill from G.H Industry is a multipurpose powerhouse designed for a variety of crop wastes, commercial byproducts, and raw wood materials. Our equipment thrives on turning these materials into premium biomass pellets, whether it be from wood chips and straw to rice husks, peanut shells, and other materials.

    G.H Industry's Wood Pellet Mill, expertly crafted, offers customized pellet sizes, ranging from 2mm to 12mm, guaranteeing an immaculate fit for your particular needs. These pellets are a game-changing fuel source that efficiently and sustainably ignites broilers and powers power plants.
    
    The MZLH Series Wood Pellet Mill from G.H Industry redefines capacity with options ranging from 0.5 to 20 tons per hour, ideally adapted to your needs. Join us as we explore the prospect of producing biomass pellets,
    `}
    imgg={'https://images.unsplash.com/photo-1634672652995-ee7525bce595?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80'}
    products={productList}
    />
    </>
  )
}
