import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'




import p22 from './Products/22p.jpg'
import p23 from './Products/23p.jpg'
import p24 from './Products/24p.jpg'
import p25 from './Products/25p.jpg'
import p26 from './Products/26p.jpg'
import p27 from './Products/27p.jpg'
import p28 from './Products/28p.jpg'
import p29 from './Products/29p.jpg'
import p30 from './Products/30p.jpg'
import p31 from './Products/31p.jpg'
import p32 from './Products/32p.jpg'
import p33 from './Products/33p.jpg'


export const FeedPelletizingSystem = () => {
  const productList = [
    {
      Pname: "SZLH-858 Feed Processing Machinery",
      Pcap: "28-45T/H",
      Mpow: "280/315kw",
      img: p22,
    },
    {
      Pname: "SZLH-768 Aquatic Feed Machine",
      Pcap: "25-40T/H",
      Mpow: "280/315kw",
      img: p23,
    },
    {
        Pname: "SZLH-678 Feed Mill Equipment",
        Pcap: "25-40T/H",
        Mpow: "220/250kw",
        img: p24,
      },
      {
        Pname: "SZLH-558 Cattle Pellet Machine",
        Pcap: "15-25T/H",
        Mpow: "180/220kw",
        img: p25,
      },
      {
        Pname: "SZLH-508 Feed Pellet Mill",
        Pcap: "10-18T/H",
        Mpow: "160kw",
        img: p26,
      },
      {
        Pname: "SZLH-508 Feed Pellet Machine",
        Pcap: "10-18T/H",
        Mpow: "160kw",
        img: p27,
      },
      {
        Pname: "SZLH-420 Pellet Making Machine",
        Pcap: "8-12T/H",
        Mpow: "110kw",
        img: p28,
      },
      {
        Pname: "SZLH-420 Feed Mill Machine",
        Pcap: "8-12T/H",
        Mpow: "110kw",
        img: p29,
      },
      {
        Pname: "SZLH-350 Feed Granulator",
        Pcap: "5-7T/H",
        Mpow: "55kw",
        img: p30,
      },
      {
        Pname: "SZLH-350 Feed Mill Machine",
        Pcap: "5-7T/H",
        Mpow: "55kw",
        img: p31,
      },
      {
        Pname: "SZLH-320 Feed Making Machine",
        Pcap: "3-4T/H",
        Mpow: "37kw",
        img: p32,
      },
      {
        Pname: "SZLH-250 Feed Pellet Machine",
        Pcap: "1-2T/H",
        Mpow: "22kw",
        img: p33,
      },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Feed Pelletizing System'}
    desc={`G.H. Feed Pellet Machines: an introduction: Boost the amount of feed you produce. Create excellent pellets from maize, wheat, soybean meal, and other ingredients. Our machines are adaptable and precise, and they work well in big industries, little mills, and farms. In addition to equipment, we provide thorough setups and dedicated service. G.H. is the engine driving your feed production adventure.

    To experience a seamless integration of innovation and competence in your feed production process, use G.H Feed Pellet Machines. Your feed production goals get the consideration and dedication they merit when you work with us, where quality meets ease. Acquire new levels of success by joining us on this trip to improve your feed production capacities.`}
    imgg={'https://images.unsplash.com/photo-1679934408481-5ebb1f180cf3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1026&q=80'}
    products={productList}
    />
    </>
  )
}
