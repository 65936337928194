    import React from 'react'
    import { Header } from './Header'
    import { Products } from './Products'
    import { ProductSeries } from './ProductSeries'



    import p20 from './Products/20p.jpg'
    import p21 from './Products/21p.jpg'


    export const BatchingSystem = () => {
    const productList = [
        {
        Pname: "Intermittent Liquid Adding Machine",
        Pcap: "50L/MIN",
        Mpow: "2.2kw",
        img: p20,
        },
        {
        Pname: "LCS Series Rotary Distributor",
        Pcap: "",
        Mpow: "",
        img: p21,
        },
        // Add more products here...
    ];
    return (
        <>
        <Header head={"Feed Equioments"}/>
        <ProductSeries />
        <Products

        heading={'Batching System'}
        desc={`With the cutting-edge Batching System products from G.H Industry, you can increase precision and efficiency in your feed production process. We have developed a variety of solutions that redefine batching accuracy and streamline your operations as a pioneer in agricultural technology.


        Our batching system solutions are made to offer exact control over mixing and measuring ingredients. Our systems guarantee consistent and accurate batching and produce high-quality feed blends that satisfy the nutritional requirements of your animals thanks to cutting-edge technology and user-friendly interfaces.`}
        imgg={'https://images.unsplash.com/photo-1515249347005-66ea7bc26847?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1490&q=80'}
        products={productList}
        />
        </>
    )
    }
