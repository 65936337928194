import React, { useState, useEffect } from "react";
import './Products.css'
import { Link } from "react-router-dom";

const ProductCard = ({ name, cap, image, pow ,Descc }) => {
    const [isPopupActive, setPopupActive] = useState(false);

    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }, []);
  
    // const togglePopup = () => {
    //   setPopupActive(!isPopupActive);
    // };
    document.title="G.H - Products";
    return (
        
        
      <div className="product" data-aos="fade-up" data-aos-delay="800">
        <div className="productt-card">
          <h2 className="namee">{name}</h2>
          <span className="price">Capacity: {cap}</span>
          <span className="pow">Main Power: {pow}</span>
          
        
          <Link className="popup-btn" to="/quote">
            Get Quote
          </Link>
          <img src={image} className="product-immg" alt="" />
        </div>
        {isPopupActive && (
          <div className="popup-view">
            <div className="popup-card">
              <a >
                <i className="fas fa-times close-btn"></i>
              </a>
              <div className="product-img">
                <img src={image} alt="" />
              </div>
              <div className="info">
                <h2>
                  {name}
                  <br />
                  <span>Product Description</span>
                </h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
                <span className="price">${cap}</span>
                <a href="#" className="add-cart-btn">
                  Add to Cart
                </a>
                <a href="#" className="add-wish">
                  Add to Wishlist
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
        
    );
  };
export const Products = (props) => {
  return (
    <>
    <div class="custom-section">
    <img class="custom-bg-image" src={props.imgg} alt="Background Image"/>
    <div class="custom-overlay"></div>
    <div class="cusstom-text">
      <h1 className="hg">{props.heading}</h1>
      <p className="hg">{props.desc}</p>
    </div>
  </div>
    
    <div className="containeer">
     


      {/* Render ProductCard components for each product */}
      {props.products.map((product, index) => (
        <ProductCard
          key={index}
          name={product.Pname}
          cap={product.Pcap}
          pow={product.Mpow}
          image={product.img}
          Descc={product.Pdes}
        />
      ))}
    {/* <ProductCard
      name={props.Pname}
      cap={props.Pcap}
      pow={props.Mpow}
      image={props.img}
    /> */}

  </div>
  </>
  )
}