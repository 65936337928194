import React, { useState } from 'react';
import styled from 'styled-components';
import slide1 from './Images/slid1.png';
import slide2 from './Images/slid2.png';
import slide3 from './Images/slid3.png';

const Background = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url('https://images.unsplash.com/photo-1584230711074-9c6edb55fd2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80');
  background-size: cover;
  background-position: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
`;

const SliderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 75rem;
  overflow: hidden;
`;

const Slider = styled.div`
  display: flex;
  transition: transform 0.3s ease-in-out;
`;

const SliderImage = styled.img`
  width: 100%;
  height: auto;
  flex-shrink: 0;
`;

const SliderButtons = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
`;

const PrevButton = styled.button`
  background-color: transparent;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  outline: none;
`;

const NextButton = styled.button`
  background-color: transparent;
  color: white;
  font-size: 24px;
  border: none;
  cursor: pointer;
  padding: 10px 15px;
  outline: none;
`;

const images = [`${slide1}`, `${slide2}`, `${slide3}`];
const imageWidth = 100;

const Section = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <Background >
      <Overlay />
      <SliderContainer>
        <Slider style={{ transform: `translateX(-${currentIndex * imageWidth}%)` }}>
          {images.map((image, index) => (
            <SliderImage key={index} src={image} alt={`Image ${index + 1}`} />
          ))}
        </Slider>
      </SliderContainer>
      <SliderButtons>
        <PrevButton onClick={prevSlide}>&#10094;</PrevButton>
        <NextButton onClick={nextSlide}>&#10095;</NextButton>
      </SliderButtons>
    </Background>
  );
};

export default Section;
