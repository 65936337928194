import React, { useState, useEffect } from 'react';
import './FAQPage.css';
import { Header } from './Header';

const FAQPage = () => {
    const [activePanels, setActivePanels] = useState([]);
    
  
    const togglePanel = (index) => {
      setActivePanels((prevPanels) =>
        prevPanels.includes(index) ? prevPanels.filter((item) => item !== index) : [...prevPanels, index]
      );
    };

    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }, []);

    document.title="G.H - FAQ";
  const faqData = [
    {
        question: 'We Have The Interest To Be A Distributor For These Biomass Fuel Pellet Mill Machine Products For Bulgaria ?',
        answer: `We are pleased to learn that you are interested in distributing our Biofuel Pellet Mills in Bulgaria and are associated with the Biopower sector. The markets around the world are flooded with G.H pellet machines. We sincerely hope that we may develop a long-lasting, cordial business connection with your prestigious firm as well. There are various models of biofuel pellet mills that we offer, and they are as follows:

        For sale is a 1300-400KG/H biofuel pellet producing machine.

        
        300-400 KG/H Capacity
        

        1.5 KW Anti-Caking Feeder Power
 

        0.55 KW Force Feeder Power
        

        Power: 37 KW


        For sale is a 2CE 500-700KG/H biofuel wood pellet producing machine.


        500-700KG/H Capacity


        1.5 KW Anti-Caking Feeder Power


        0.55 KW Force Feeder Power


        Energy: 55 KW


        1-1.2T/H biofuel pellet production equipment for sale with 3CE certification


        1-1.2T/H of capacity

        
        1.5 KW Anti-Caking Feeder Power

        
        0.55 KW Force Feeder Power
        

        Energy: 55 KW

        
        1-1.2T/H biofuel pellet production equipment for sale with 3CE certification

        

        1-1.2T/H of capacity

        

        1.5 KW Anti-Caking Feeder Power

        

        0.55 KW Force Feeder Power

        

        Energy: 90 KW

        

        2-2.5 ton per hour, super-quality biofuel pellet mill, 4CE ISO approved

        

        2-2.5T/H of capacity

        

        2.2 KW Anti-Caking Feeder Power

        

        1.5 KW Force Feeder Power

        

        Energy: 132 KW

        

        In the meanwhile, we are including some of G.H Industry Pellet Mills' prices in this email's attachment for your information. Please confirm and let us know which of our machine models you are interested in. Please let us know so we can give you the information you need. `,
      },
      {
        question: `What's The Meaning Of GJ? What's The Calorific Value Of Wood Pellets?`,
        answer: `GJ stands for global joule of heat. J (joules) is the fundamental unit. Additionally, there are extension units like MJ and GJ.

        1GJ = 1000MJ; 1MJ = 1000J
        
        1000 Cal = 1 Kcal = 4186.8 J = 4.1868 KJ
        
        1 kcal equals 4.1868 MJ, and 10 kcal equals 41.868 MJ.
        
        1GJ = 1000MJ = 238845.9 kcal
        
        The calorific value of burning 55.55kg (4300 kcal) of coal produces 1GJ. 18 MJ per kilogram of coal, or 4300 kcal/kg.
        
        Several fuels' calorific value
        
        Gas: 8600 kcal/m3, which is 36 MJ/m3 in terms of energy.
        
        Liquefied petroleum gas has 11000 kcal per kilogram, or 46 MJ per kg.
        
        12000 kcal/kg of automotive diesel fuel, or 50.24 MJ/kg
        
        Motor gasoline: 10800 kcal per kilogram, or 45.22 MJ per kilogram.
        
        Wood pellets have 4300 kcal per kg, or 18 MJ per kg.
        
        straw pellets: 3700 kcal per kg, or 15.49 MJ per kg.`,
      },
      {
        question: `Why Is The Feed Mildew?　`,
        answer: `1. Reason:

        (1) Wet feed, inappropriate storage, and rain
        
        (2) In winter, pay attention to corn stored in tall silos since there is a significant (more than 5°C) temperature difference between the temperature of the grain and the air outside.
        
        (3) The completed product is blended with the trash from the granulator and the equipment's wall-hanging material.
        
        (4) The return material processing procedure is wrong.
        
        2. Fix:
        
        (1) Pads and thatch shed cloth storage and transit during wet days;
        
        (2) Keep merchandise for clients in a cool, dry area;
        
        (3) To ensure the cooling effect, adjust the cooling air volume and cooling duration;
        
        (4) Consistently and routinely clean the machinery (mixer, chiller, granulator);
        
        (5) After re-granulating and crushing the returning granular material, it should be disposed of responsibly. Directly incorporating it into the end product is strictly prohibited.

        (6) Regularly ventilate the silo and inspect the maize within.`,
      },
      {
        question: `Poor Packaging Quality, Wrong Packaging Labels?`,
        answer: `1. Reason:

        (1) Poor package quality and lax quality control (outsourcing of weaving, inner membrane)
        
        (2) The packer shows negligence
        
        (3) The original label and package are combined with those from other businesses.
        
        2. Solution:
        
        (1) Implement the local packaging acceptance requirements while strictly enforcing the package quality standards.
        
        (2) Staff training, close attention to detail, better operation techniques, facing the operator in same label and packaging, prompt control and coordination with the supply unit, control of product quality at the source, and confusion prevention.`,
      },
      {
        question: `What Makes A Chicken Binder Great?`,
        answer: `If a chicken binder is made with high quality, they help a lot with preventing the food from heat and moisture. It has other great factors as well as reducing energy costs, protecting the pellets from being ruined during transport, and reducing the process of pellet crumbling.`,
      },
      {
        question: `Why Add Salt To The Chicken Diet?`,
        answer: `Salt can improve the palatability of diets, promote appetite, and increase feed utilization. If there is insufficient salt in the chicken's diet, it will cause loss of appetite and digestive disorders, slow growth of the chicks, pecking addiction, and the weight and egg weight of the laying hens will decrease, and the egg production rate will decrease. The salt content of the chicken's diet should be 0.37% to 0.5%, and the dosage must be accurate. When formulating diets, the salt content of animal feed should be considered, and then the amount of supplementary salt should be determined.

        For example, when salted fish meal is used in the diet, its salt content must be analyzed first, otherwise excessive salted fish meal will often cause salt poisoning. Chlorine and sodium elements exist in the body fluids, soft tissues and eggs of the chicken body, which can maintain the acid-base balance of the chicken body, maintain the balance of osmotic pressure between cells and blood, and keep the chicken body tissues with a certain amount of water.`,
      },
      {
        question: `What Is The Reason For The Large Color Change Of Pig Concentrate Feed?`,
        answer: `1. Reason:

        (1) Changes in the sensory quality of raw materials (soybean meal, peanut meal) from various suppliers (factories).
        
        
        
        (2) Improper raw material usage (Kushikura).
        
        
        
        (3) The composition of formula raw materials has altered as a result of the raw material scarcity.
        
        (4) The head and tail of the storehouse are incorporated into the finished product, together with the leftover components of the machinery.
        
        
        
        2. Solution:
        
        
        
        (1) Make sure the raw material's source is stable and pay attention to the raw material's sensory quality.
        
        (2) Stable formula structure to prevent a lack of raw materials.
        
        
        
        (3) To avoid confusion with poultry feed, only the raw materials for pig feed are kept and used.
        (4) Entry into the finished product and packing it for final inspection are strictly prohibited, with the exception of the warehouse head and warehouse tail materials.
        (5) Create a strategy for using raw resources, promptly replenish inventory, and guarantee continuous manufacturing.

        (6) Everyone comes to an agreement, pays attention to one another, and controls how it is carried out.`,
      },
      {
        question: `What Are The Manifestations Of Poor Performance Of Feed Products?`,
        answer: `(1) Small feed intake, diarrhea, periodic feather pecking, feed, paralysis, high feed to meat ratio; 　　

        (2) The pellets have high powder content, high pellet hardness, dry finished products, and uneven pellet sizes.`,
      },
      {
        question: `What Is Biofloc Technology？`,
        answer: `Water waste can be removed using the Biofloc system. Water entering farms has already been treated using this method. To prevent the spread of infections, this is done. The method makes use of some beneficial bacteria. These probiotics are. These probiotics are able to eliminate dangerous germs. to keep water quality high. They can also change water-soluble nitrogen into protein. 

        The fusion of several chemicals is called biofloc. Here, a lot of particles stick together and create a flocculent. There could be bacteria, protozoa, algae, organic muck, or uneaten fish food. All of the particles remain flocculent because of the mucus the bacteria make. The electrostatic force can occasionally assist in keeping them together.
        
        1. What Role Does Biofloc Play in Fish Farming?
        
        The primary drawback of intensive fish rearing in ponds is maintaining the water quality. In the past, the biofloc process frequently required complete water recycling. Water, however, isn't always a gift that is freely given. Additionally, the expansion of fish is disturbed when old water is replaced with new water. Thus, these problems were resolved by biofloc.

Ammonia is the major substance that prevents fish from growing, and only a fish farmer is aware of how cruel ammonia can be. Probiotics are frequently used in fish aquaculture with biofloc. It makes use of the ammonia in the tank and multiplies by a replica. As they prepare for their colony, they eventually turn the dangerous ammonia and other materials into proteins.
These proteins are consumed by the fish in the aquariums. Fish feed pellets are provided to the fish in less quantity as a result. The proteins from the flocs are already being fed to them. As a result, the cost of feeding drops by 20% to 30%.

2. Advantages Of The Biofloc Culture System

• Eco-friendly cultural framework.

It lessens the influence on the environment.

•Boosts water and land use effectiveness.

• Minimal or no exchange of water.

• Greater productivity (It improves fish culture systems' feed conversion, growth performance, and survival rate).

•Enhanced biosecurity.

•Reduces the risk of pathogen introduction and dissemination as well as water contamination.

•Production of fish feed that is affordable.

•It lowers the price of regular feed and the usage of protein-rich feed.

3. Negative aspects of the Biofloc technology

•Aeration and mixing now demand more energy.
•Decreased response time due to increased water respiration rates.

•The necessary start-up time.

Supplementing with alkalinity is necessary.

•Increased pollution risk due to nitrate buildup.

• Seasonal and inconsistent performance for systems exposed to sunshine.

The nutritional value of biologic was found to be good. Protein makes up 25–50% of the dry weight while fat makes up 0.5–15%. It is an excellent source of minerals and vitamins, particularly phosphorus. It also HAS probiotic-like ACTIONS. Fish meal or soy should be substituted in the fish feed pellet with dry biological flocs.
`,
      },
      {
        question: `Can Cacti Be Used As Feed?`,
        answer: `Cactus stems are substantially richer in complete nutrients and trace elements than other crops, and they are also quite succulent. Cactus is also simple to grow and has a large output. In some places, cactus are currently year-round planted and utilized as animal fodder.

        To rear pigs, sheep, cattle, and other livestock and fowl, mix cactus feed with other feed. It has become the only and best source of bulk fodder in some desert places due to its excellent breeding effects. Therefore, actively advancing cactus fodder is crucial for the advancement of food-saving animal husbandry as well as the resolution of difficulties such as the competition between humans and animals for food.
`,
      },
      {
        question: `Some Other Questions ?`,
        answer: `Contact US At 
        Email: 
        Gh.industry.pk@gmail.com
        Call us :
        +92-300-7448688 ,
        +92-0301-6367000
`,
      },
      
  ];


  const blogData = [
    {
      id: 1,
      imageSrc: 'https://images.unsplash.com/photo-1560493676-04071c5f467b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80', // Replace with your image paths
      title: 'Company',
      content: 'G.H. Industry, a leading feed mill company committed to providing the livestock industry with high-quality nutrition solutions.',
    },
    {
      id: 2,
      imageSrc: 'https://images.unsplash.com/photo-1625246333195-78d9c38ad449?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
      title: 'Work',
      content: 'We delve into the innovations and procedures that make us unique, such as precise ingredient blending, strict quality controls, and environmentally friendly production methods.',
    },
    {
      id: 3,
      imageSrc: 'https://images.unsplash.com/photo-1499529112087-3cb3b73cec95?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1074&q=80',
      title: 'Care',
      content: 'Here, we emphasize how G.H. Industry adapts feed formulas to satisfy the unique dietary needs of distinct animals at various life phases. ',
    },
    {
      id: 3,
      imageSrc: 'https://images.unsplash.com/photo-1454179083322-198bb4daae41?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
      title: 'Livestock',
      content: 'Through cutting-edge feed solutions, G.H. Industry sets the standard for animal nutrition, improving livestock health and performance.',
    },
  ];



  useEffect(() => {
    const newIntervals = activePanels.map((index) => {
      
      const paragraph = document.getElementById(`paragraph_id${index + 1}`);
      const myDiv = document.getElementById(`myDiv_id${index + 1}`);

      if (!paragraph || !myDiv) return null;

      myDiv.textContent = ''; // Clear previous content

      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex >= paragraph.textContent.length) {
          clearInterval(intervalId);
          return;
        }

        myDiv.textContent += paragraph.textContent.charAt(currentIndex);
        currentIndex++;
      }, 1); // Speed up the typewriter effect by reducing the interval time

      return intervalId;
    });

    // Clear intervals when the component unmounts or when activePanels change
    return () => {
      newIntervals.forEach((intervalId) => {
        clearInterval(intervalId);
      });
    };
  }, [activePanels]);

  return (
    <>
    <Header head={'FAQ'} />

    <div className="blog-section">
      {blogData.map((blog) => (
        <div key={blog.id} className="blog-item">
          <div
            className="blog-image"
            style={{ backgroundImage: `url(${blog.imageSrc})` }}
          >
            <div className="overlay">
              <h2>{blog.title}</h2>
              <p style={{textAlign: 'center'}}>{blog.content}</p>
            </div>
          </div>
        </div>
      ))}
    </div>



    <div className="w3-center ws-black w3-animate-zoom" style={{ marginTop: '-1px' }}>
      <div className="w3-center w3-content">
        <div className="faq w3-center w3-content" style={{ paddingTop: '55px' }}>
          <h2 className="heaader" >
            <b>Frequently Asked Questions</b>
          </h2>
          <br />
          {faqData.map((faq, index) => (
            <div key={index}>
              <button
                className={`accordion ${activePanels.includes(index) ? 'active' : ''}`}
                onClick={() => togglePanel(index)}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <img
                  className="faq-icon"
                  src={`https://cdn.jsdelivr.net/gh/linuxguist/faqa@main/${
                    activePanels.includes(index) ? 'chevron-up' : 'chevron-down'
                  }.svg`}
                  alt="chevron"
                />
                &nbsp; &nbsp;
                <b style={{ paddingTop: '2px' }}>{faq.question}</b>
              </button>
              <div
                id={`panel${index}`}
                className={`panel ${activePanels.includes(index) ? 'active' : ''}`}
                style={{ display: activePanels.includes(index) ? 'block' : 'none' }}
              >
                <div className="paragraphs" id={`myDiv_id${index + 1}`} />
                <p id={`paragraph_id${index + 1}`} style={{ display: 'none' }}>
                  {faq.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
        <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/linuxguist/faqa@main/styles.css"/>
        <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/gh/linuxguist/faqa@main/stylesheet.css"/>
      </div>
    </div>


    </>
  );
};

export default FAQPage;