import React from 'react'
import { Header } from './Header'
import { Products } from './Products'



import p41 from './Products/41p.jpg'
import p42 from './Products/42p.jpg'
import p43 from './Products/43p.jpg'
import p44 from './Products/44p.jpg'
import { ProductSeriesWood } from './ProductSeriesWood'


export const WoodCrusher = () => {
  const productList = [
    {
      Pname: "Wood Pallet Crushing Machine",
      Pcap: "8-12T/H",
      Mpow: "242.2kw",
      img: p41,
    },
    {
      Pname: "Horizontal Drum Wood Chipper",
      Pcap: "3-5T/H",
      Mpow: "4-45kw",
      img: p42,
    },
    {
        Pname: "SFSP Wood Crusher",
        Pcap: "3-25T/H",
        Mpow: "110kw",
        img: p43,
      },
      {
        Pname: "Wood Chipper Crusher",
        Pcap: "3-5T/H",
        Mpow: "30kw",
        img: p44,
      },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Wood Equioments"}/>
    <ProductSeriesWood />
    <Products

    heading={'Wood Crusher'}
    desc={`Introducing the Wood Crusher from G.H. Industry, the top tool for cutting wood efficiently. Our Wood Crusher is intended to break down wood into tiny particles that can be used as fuel, bedding for animals, and other things.


    G.H Industry's Wood Crusher guarantees constant and dependable performance with its precise engineering and durable construction. It effectively cuts down on wood waste, helping to preserve the environment and use resources sustainably.
    
    
    
    Our Wood Crusher is a flexible complement to your business, whether you run a woodworking plant, a biomass energy producer, or a furniture manufacturer. It successfully transforms waste wood into useful resources, fostering both economic and ecological advantages.
    `}
    imgg={'https://images.unsplash.com/photo-1529957725217-06cd3292ac91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80'}
    products={productList}
    />
    </>
  )
}
