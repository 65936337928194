import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen, faLocationDot , faPhone, faTools, faGears ,faShieldHeart , faMinus,faHandshake} from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faGoogle, faGooglePlus, faTelegramPlane, faTwitter, faWhatsapp, faYoutube , } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';





const Footer = () => {
  return (
<>
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
<div class="floating_btn">
  <a target="_blank" href="https://api.whatsapp.com/send?phone=9203016367000&text=Hi there! I'm from G.H Industry's website 🌐🏭 Just wanted to let you know that you can easily reach out to us on WhatsApp too. Feel free to tap the button below to start a chat. Looking forward to assisting you! 💬📞 #GHIndustry">
    <div class="contact_icon">
      <i class="fa fa-whatsapp my-float"></i>
    </div>
  </a>

</div>

<section id="service">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="section_title text-center">
          <h2>More Info</h2>
          <div class="brand_border">
          <FontAwesomeIcon icon={faMinus} className="i" />

          <FontAwesomeIcon icon={faHandshake} className="i" />

          <FontAwesomeIcon icon={faMinus} className="i" />

          </div>
          <p>G.H Industry (Pvt.) LTD is the name of Trust. We are the biggest Manufacturer and Fabricator Company in Pakistan</p>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-md-6 col-lg-4 col-sm-12" data-aos="fade-up" data-aos-delay="1000">
        <div class="single_service hvr-curl-top-right">
          <div class="single_service-left">
            <div class="icon">
            <FontAwesomeIcon icon={faTools} className="i" />

            </div>
          </div>
          <div class="single_service-body" >
            <h4 class="single_service-heading">Service Production</h4>
            <p>G.H Industry
We will provide comprehensive services to customers’satisfaction.</p>
          </div>
        </div>
      </div>


      <div class="col-md-6 col-lg-4 col-sm-12" data-aos="fade-up" data-aos-delay="800">
        <div class="single_service hvr-curl-top-right">
          <div class="single_service-left">
            <div class="icon">
            <FontAwesomeIcon icon={faGears} className="i" />

            </div>
          </div>
          <div class="single_service-body">
            <h4 class="single_service-heading">Parts</h4>
            <p>We provide our customers with a complete range of feed and biomass equipment accessories</p>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-4 col-sm-12" data-aos="fade-up" data-aos-delay="500">
        <div class="single_service hvr-curl-top-right">
          <div class="single_service-left">
            <div class="icon">
            <FontAwesomeIcon icon={faShieldHeart} className="i" />

            </div>
          </div>
          <div class="single_service-body">
            <h4 class="single_service-heading">TurnKey</h4>
            <p>With professional knowledge and unremitting efforts to create suitable project solutions.</p>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>




    <footer className="footer-section" >
      <div className="container">
        <div className="footer-cta pt-5 pb-5">
          <div className="row">
            {/* Footer CTA columns */}
            {/* Replace the following columns with your React components as needed */}
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
              <FontAwesomeIcon icon={faLocationDot} className='i' shake />
                <div className="cta-text">
                  <h4>Find us</h4>
                  <span>P.O Climax Octri Post <br/>Samanabad,Gujranwala</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
              <FontAwesomeIcon icon={faPhone} shake className='i' />
                <div className="cta-text">
                  <h4>Call us</h4>
                  <span>+92-300-7448688 ,<br/> +92-0301-6367000</span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="single-cta">
              <FontAwesomeIcon icon={faEnvelopeOpen} shake className='i' />

                <div className="cta-text">
                  <h4>Mail us</h4>
                  <span>Gh.industry.pk@gmail.com</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            {/* Footer Content columns */}
            {/* Replace the following columns with your React components as needed */}
            <div className="col-xl-4 col-lg-4 mb-50">
              {/* Footer Widget */}
              {/* Replace the following with your footer widget content */}
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/"><img src="https://i.ibb.co/sb2HbXw/qrcode-1.png" className="img-fluid" alt="logo" /></a>
                </div>
                <div className="footer-text">
                  <p>G.H Industry (Pvt.) LTD is the name of Trust.
We are the biggest Manufacturer and Fabricator Company in Pakistan</p>
                </div>
                <div className="footer-social-icon">
                  <span>Follow us</span>
                  <a href="https://www.facebook.com/profile.php?id=100064021328744&mibextid=ZbWKwL"><FontAwesomeIcon icon={faFacebookF} bounce className='i facebook-bg'/></a>
                  <a href="https://youtube.com/@GHindustries"><FontAwesomeIcon icon={faYoutube} bounce className='i google-bg'/></a>
                  <a href="https://api.whatsapp.com/send?phone=9203016367000&text=Hi there! I'm from G.H Industry's website 🌐🏭 Just wanted to let you know that you can easily reach out to us on WhatsApp too. Feel free to tap the button below to start a chat. Looking forward to assisting you! 💬📞 #GHIndustry"><FontAwesomeIcon icon={faWhatsapp} bounce  className='i twitter-bg'/></a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              {/* Useful Links Widget */}
              {/* Replace the following with your useful links widget content */}
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Useful Links</h3>
                </div>
                <ul>
                <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/service">Service</Link></li>
                  <li><Link to="/faq-page">FAQ</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                  <li><Link to="/customer-guide">Customer Guide</Link></li>
                  <li><Link to="/feed-cleaning-system">Feed Equipments</Link></li>
                  <li><Link to="/wood-crusher">Wood Equipments</Link></li>
                  <li><Link to="/parts">Genuine Spare Parts</Link></li>
                  <li><Link to="/quote">Get Quote</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              {/* Subscribe Widget */}
              {/* Replace the following with your subscribe widget content */}
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Get Quote</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>Don’t miss to check our new feeds, <Link to="/quote" style={{color: 'white'}}>Click Here </Link>to get the Quote</p>
                </div>
                <div className="subscribe-form">
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
              <div className="copyright-text">

              
                <p>Copyright &copy; 2023, All Right Reserved <Link to="/">G.H Industry</Link></p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              {/* Footer Menu */}
              {/* Replace the following with your footer menu content */}
              <div className="footer-menu">
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about">About</Link></li>
                  <li><Link to="/service">Service</Link></li>
                  <li><Link to="/faq-page">FAQ</Link></li>
                  <li><Link to="/contact">Contact Us</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    </footer>






    </>
  );
};

export default Footer;