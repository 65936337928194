import React from 'react';
import "./ProductSeries.css"
import { Link } from 'react-router-dom';

const Button = ({ color, children, link }) => {
  const buttonStyle = {
    '--color': color
  };
  // console.log(link);
  
  return (
    <Link className="button" to={link} style={buttonStyle}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {children}
    </Link>
  );
};
export const ProductSeries = () => {
  return (
    <>
      <h5 className='p-s' >Product Series</h5>
    <div className="contaainer">
      <Button color="#1e9bff" link="/feed-cleaning-system">Feed Cleaning System</Button>
      <Button color="#1e9bff" link="/feed-crushing-system">Feed Crushing System</Button>
      <Button color="#1e9bff" link="/feed-mixing-system">Feed Mixing System</Button>
      <Button color="#1e9bff" link="/batching-system">Batching System</Button>
      <Button color="#1e9bff" link="/feed-pelletizing-system">Feed Pelletizing System</Button>
      <Button color="#1e9bff" link="/feed-cooling-machine">Feed Cooling Machine</Button>
      <Button color="#1e9bff" link="/packaing-system">Packaging System</Button>
      <Button color="#1e9bff" link="/conveyor-system">Conveyor System</Button>
      <Button color="#1e9bff" link="/auxiliary-equipment">Auxiliary Equipment</Button>
    </div>
    </>
  )
}
