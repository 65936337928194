import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'


import p45 from './Products/45p.jpg'
import p46 from './Products/46p.jpg'
import p47 from './Products/47p.jpg'
import { ProductSeriesWood } from './ProductSeriesWood'


export const WoodDryer = () => {
  const productList = [
    {
      Pname: "Roller Drying Machine",
      Pcap: "",
      Mpow: "",
      img: p45,
    },
    {
      Pname: "Rotary Drum Dryer Machine",
      Pcap: "",
      Mpow: "",
      img: p46,
    },
    {
        Pname: "HRHG Series Multi-Pass Convection Belt Dryer",
        Pcap: "3-5T/H",
        Mpow: "46.85kw",
        img: p47,
      },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Wood Equioments"}/>
    <ProductSeriesWood />
    <Products

    heading={'Wood Dryer'}
    desc={`Introducing the Wood Dryer from G.H Industry, the key component of effective wood processing. Our Wood Dryer is expertly designed to take moisture out of wood products, providing the best quality for a variety of uses like woodworking, building, and more.

    The Wood Dryer from G.H Industry offers precise controls and cutting-edge technology for reliable and complete drying. The moisture level is greatly reduced, improving the strength, use, and longevity of wood products.
    
    Our Wood Dryer is an essential tool for any company working in the wood-based industries, including timber mills, furniture makers, and others. It quickens the drying process, increasing output while consuming less energy.
    
    `}
    imgg={'https://images.unsplash.com/photo-1655801945570-1175435b5f20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1440&q=80'}
    products={productList}
    />
    </>
  )
}
