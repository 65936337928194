
import React from 'react';
import "./ProductSeries.css"
import { Link } from 'react-router-dom';

const Button = ({ color, children, link }) => {
  const buttonStyle = {
    '--color': color
  };
  // console.log(link);
  
  return (
    <Link className="button" to={link} style={buttonStyle}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {children}
    </Link>
  );
};
export const ProductSeriesWood = () => {
  return (
    <>
      <h5 className='p-s' >Product Series</h5>
    <div className="contaainer">
      <Button color="#1e9bff" link="/wood-crusher">Wood Crusher</Button>
      <Button color="#1e9bff" link="/wood-dryer">Wood Dryer</Button>
      <Button color="#1e9bff" link="/wood-pellet-mill">Wood Pellet Mill</Button>
      <Button color="#1e9bff" link="/pellet-cooler">Pellet Cooler</Button>
      <Button color="#1e9bff" link="/packaing-system">Packaging System</Button>
      <Button color="#1e9bff" link="/conveyor-system">Conveyor System</Button>
      <Button color="#1e9bff" link="/auxiliary-equipment">Auxiliary Equipment</Button>
    </div>
    </>
  )
}