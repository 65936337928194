import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'



import p37 from './Products/37p.jpg'
import p38 from './Products/38p.jpg'
import p39 from './Products/39p.jpg'


export const ConveyorSystem = () => {
  const productList = [
    {
      Pname: "TSLL Series Screw Conveyor",
      Pcap: "10-15M^3/H",
      Mpow: "2.2kw",
      img: p37,
    },
    {
      Pname: "TGSS Series Scraper conveyor",
      Pcap: "40M^3/H",
      Mpow: "3kw",
      img: p38,
    },
    {
      Pname: "TDTG Series Bucket Elevator",
      Pcap: "60m^3",
      Mpow: "5.5kw",
      img: p39,
    },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Conveyor System'}
    desc={`Introducing the Conveyor System from G.H. Industry, the foundation for streamlined and effective material handling in your feed processing operations. Our conveyor system's thorough design ensures a quick and efficient workflow by streamlining the transportation of both raw materials and finished goods.

    G.H Industry understands the need of reliable material transport in maximizing output. With the help of our Conveyor System, you can easily convey the components for feed, enabling perfect mixing, processing, and packing.
    
    Our Conveyor System is versatile and customizable, making it appropriate for a range of feed production installations, from big factories to little mills and farms. It decreases manual work, shortens downtime for operations, and increases overall production.
    
    Select the G.H Industry Conveyor System to transform your feed processing procedures.`}
    imgg={'https://images.unsplash.com/photo-1652211955967-99c892925469?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'}
    products={productList}
    />
    </>
  )
}
