import React from 'react';
import "./Solution.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBowlFood,  faToolbox } from '@fortawesome/free-solid-svg-icons';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';

const Solution = () => {
  return (
    <>
    <div className='bodyyy'>
    <section class="con0tainer ">
        <h1 className='fdsbhjk'>SYSTEM SOLUTIONS</h1>
  <section class="card__c0ontainer">
    <div class="card__bx" style={{'--clr': '#89ec5b'}}>
      <div class="card__data">
        <div class="card__icon">
          <FontAwesomeIcon icon={faCanadianMapleLeaf}/>
        </div>
        <div class="card__content">
          <h3>Animal Feed Pellet Production Line</h3>
          <p>Suitable for processing various animal pellet feed, premix feed, concentrated feed, compound feed, etc. We will customize products, technologies and overall solutions that can help customers succeed according to their actual needs.</p>
          <a href="/feed-pelletizing-system">Read More</a>
        </div>
      </div>
    </div>
    <div class="card__bx" style={{'--clr': '#eb5ae5'}}>
      <div class="card__data">
        <div class="card__icon">
        <FontAwesomeIcon icon={faBowlFood}/>
        </div>
        <div class="card__content">
          <h3>Biomass Wood Pellet Production Line</h3>
          <p>Suitable for the production of biomass pellets using wood chips, straw, palm shells and other raw wood materials, agricultural and forestry waste as the main raw materials. We will provide turn-key solution services.</p>
          <a href="/wood-pellet-mill">Read More</a>
        </div>
      </div>
    </div>
    <div class="card__bx" style={{'--clr': '#5b98eb'}}>
      <div class="card__data">
        <div class="card__icon">
        <FontAwesomeIcon icon={faToolbox}/>
        </div>
        <div class="card__content">
          <h3>Customized Your Production Line with us</h3>
          <p>Efficiency requires that your production line be customized. It increases brand credibility and competitiveness in the market by lowering waste, ensuring efficient resource usage, and satisfying specific customer expectations.</p>
          <a href="/contact">Read More</a>
        </div>
      </div>
    </div>

  </section>

</section>
</div>
</>
  )
}

export default Solution;