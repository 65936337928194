import React from 'react'
import { Header } from './Header'
import { Products } from './Products'


import p40 from './Products/40p.jpg'
import { ProductSeries } from './ProductSeries'


export const AuxiliaryEquipment = () => {
const productList = [
    {
    Pname: "Nothing To Show",
    Pcap: "N/A",
    Mpow: "N/A",
    img: p40,
    },
    // Add more products here...
];
return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Auxiliary Equipment'}
    desc={`Introducing G.H Industry's Auxiliary Equipment, your journey's indispensable partners in optimizing every aspect of feed processing. Our selection of auxiliary equipment is made to effortlessly connect with your activities, improving performance all around.
    
    Our Auxiliary Equipment is proof of our dedication to all-encompassing solutions, from moisture control units that maintain feed quality to dust collecting systems that guarantee a clean and safe workplace.`}
    imgg={'https://images.unsplash.com/photo-1438129570023-55d4a53b30b9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1073&q=80'}
    products={productList}
    />
    </>
)
}
