import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'


import p8 from './Products/8p.jpg'
import p9 from './Products/9p.jpg'
import p10 from './Products/10p.jpg'
import p11 from './Products/11p.jpg'
import p12 from './Products/12p.jpg'
import p13 from './Products/13p.jpg'

export const FeedCrushingSystem = () => {
  const productList = [
    {
      Pname: "SFSP Feed Hammer Mill",
      Pcap: "6-9TPH",
      Mpow: "75kw",
      img: p8,
    },
    {
      Pname: "Grass Crushing Machine",
      Pcap: "1-2TPH",
      Mpow: "75kw",
      img: p9,
    },
    {
      Pname: "SFSP Animal Feed Crusher",
      Pcap: "10-13TPH",
      Mpow: "110kw",
      img: p10,
    },
    {
      Pname: "SFSP Animal Feed Grinding Machine",
      Pcap: "8-10TPH",
      Mpow: "90kw",
      img: p11,
    },
    {
      Pname: "SFSP Animal Feed Crushing Machine",
      Pcap: "4-6TPH",
      Mpow: "37kw",
      img: p12,
    },
    {
      Pname: "SSLG Series Pellet Crumbler",
      Pcap: "3-8TPH",
      Mpow: "5.5kw",
      img: p13,
    },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Feed Crushing System'}
    desc={`Modern Feed Crushing System items from G.H Industry will improve your ability to produce feed. We provide a variety of solutions as a leader in agricultural technology that are intended to optimize your feed processing and boost general effectiveness.

    Our commitment to providing excellence is embodied by our Feed Crushing System products. We have developed machinery that finely smashes and processes feed ingredients, assuring uniformity and consistency in every batch, through creative design and cutting-edge engineering.
    
    
    
    Discover the strength of accuracy with our feed crushing system. Our products are designed to satisfy your unique demands, whether you run a small family farm or a large commercial business. They will streamline the preparation of your feed and improve the general health and performance of your animals.`}
    imgg={'https://images.unsplash.com/photo-1476916713558-2842194a8e49?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80'}
    products={productList}
    />
    </>
  )
}
