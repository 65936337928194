import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About } from './components/About';
import { News } from './components/News';
import { Videos } from './components/Videos';
import { Media } from './components/Media';
import { Service } from './components/Service';
import { Parts } from './components/Parts';
import { Contact } from './components/Contact';
import { FeedCleaningSystem } from './components/FeedCleaningSystem';
import { FeedCrushingSystem } from './components/FeedCrushingSystem';
import { FeedMixingSystem } from './components/FeedMixingSystem';
import { BatchingSystem } from './components/BatchingSystem';
import { FeedPelletizingSystem } from './components/FeedPelletizingSystem';
import { FeedCoolingMachine } from './components/FeedCoolingMachine';
import { PackaingSystem } from './components/PackagingSystem';
import { ConveyorSystem } from './components/ConveyorSystem';
import { AuxiliaryEquipment } from './components/AuxiliaryEquipment';
import { WoodCrusher } from './components/WoodCrusher';
import { WoodDryer } from './components/WoodDryer';
import { WoodPelletMill } from './components/WoodPelletMill';
import { PelletCooler } from './components/PelletCooler';
import  FAQPage  from './components/FAQPage';
import { CustomerGuide } from './components/CustomerGuide';
import LoadingBar from 'react-top-loading-bar';
import { Error } from './components/Error';
import { Quote } from './components/Quote';




const App = () => {

  return (
<>
<BrowserRouter>
      <LoadingBar
        color='#2581dc'
        progress={100}
        height={2}
        />
      <Navbar />

      <Routes>
        
        <Route exact path='/' element={<Homepage />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/news' element={<News />} />
        <Route exact path='/videos' element={<Videos />} />
        <Route exact path='/social-media' element={<Media />} />
        <Route exact path='/feed-cleaning-system' element={<FeedCleaningSystem />} />
        <Route exact path='/feed-crushing-system' element={<FeedCrushingSystem />} />
        <Route exact path='/feed-mixing-system' element={<FeedMixingSystem />} />
        <Route exact path='/batching-system' element={<BatchingSystem />} />
        <Route exact path='/feed-pelletizing-system' element={<FeedPelletizingSystem />} />
        <Route exact path='/feed-cooling-machine' element={<FeedCoolingMachine />} />
        <Route exact path='/packaing-system' element={<PackaingSystem />} />
        <Route exact path='/conveyor-system' element={<ConveyorSystem />} />
        <Route exact path='/auxiliary-equipment' element={<AuxiliaryEquipment />} />
        <Route exact path='/wood-crusher' element={<WoodCrusher />} />
        <Route exact path='/wood-dryer' element={<WoodDryer />} />
        <Route exact path='/wood-pellet-mill' element={<WoodPelletMill />} />
        <Route exact path='/pellet-cooler' element={<PelletCooler />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/parts' element={<Parts />} />
        <Route exact path='/customer-guide' element={<CustomerGuide />} />
        <Route exact path='/faq-page' element={<FAQPage />} />
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/quote' element={<Quote />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
</BrowserRouter>
      </>
  );
};

export default App;
