import React from 'react';
import './Media.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faFacebookF, faInstagram, faLinkedin, faLinkedinIn, faPinterest, faPinterestP, faSquarePinterest, faSquareYoutube, faTwitter, faYoutube, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { Header } from './Header';



export const Media = () => {
  document.title="G.H - Social Media";
  return (
    <>
    <Header head={'Social Media'} />
    <div id="circ">
    <ul className='ul'>
    <a href='https://www.facebook.com/profile.php?id=100064021328744&mibextid=ZbWKwL'>
      <li class="animated zoomIn li">
        <div class="back"></div>
        <div class="iconnn">
          
          <FontAwesomeIcon icon={faFacebookF} beat/>
        </div>
      </li>
      </a>
      {/* <li class="animated zoomIn delayone li">
        <div class="back"></div>
        <div class="iconnn">
          <FontAwesomeIcon icon={faTwitter} beat />
        </div>
      </li> */}
      {/* <li class="animated zoomIn delaytwo li">
        <div class="back"></div>
        <div class="iconnn">
        <FontAwesomeIcon icon={faLinkedinIn} beat />

        </div>
      </li> */}
      <a href='https://youtube.com/@GHindustries'>
      <li class="animated zoomIn delaythree li">
        <div class="back"></div>
        <div class="iconnn">
        <FontAwesomeIcon icon={faYoutube} beat />

        </div>
      </li>
      </a>
      {/* <li class="animated zoomIn delayfour li">
        <div class="back"></div>
        <div class="iconnn">
        <FontAwesomeIcon icon={faInstagram} beat />

        </div>
      </li> */}
      {/* <li class="animated zoomIn delayfive li">
        <div class="back"></div>
        <div class="iconnn">
          <FontAwesomeIcon icon={faPinterestP} beat />
        </div>
      </li> */}
    </ul>
    <h1 class="animated fadeIn delaysix h111">Social Networks</h1>
  </div>

  </>
  )
}
