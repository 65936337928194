import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'



import p14 from './Products/14p.jpg'
import p15 from './Products/15p.jpg'
import p16 from './Products/16p.jpg'
import p17 from './Products/17p.jpg'
import p18 from './Products/18p.jpg'
import p19 from './Products/19p.jpg'

export const FeedMixingSystem = () => {
  const productList = [
    {
      Pname: "SLHJ Stainless Steel Feed Mixer",
      Pcap: "1000KG/BATCH",
      Mpow: "22kw",
      img: p14,
    },
    {
      Pname: "SLHY Feed Mixing Machine",
      Pcap: "1000KG/BATCH",
      Mpow: "30kw",
      img: p15,
    },
    {
      Pname: "SLHJ Feed Mixer Machine",
      Pcap: "1500KG/BATCH",
      Mpow: "30kw",
      img: p16,
    },
    {
      Pname: "SLHY Ribbon Feed Mixer",
      Pcap: "250KG/BATCH",
      Mpow: "4kw",
      img: p17,
    },
    {
      Pname: "SLHSJ Biaxial Feed Mixer",
      Pcap: "500KG/BATCH",
      Mpow: "7.5kw",
      img: p18,
    },
    {
      Pname: "SLHJ Animal Feed Mixing Machine",
      Pcap: "500KG/BATCH",
      Mpow: "11kw",
      img: p19,
    },
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Feed Mixing System'}
    desc={`Introducing the cutting-edge Feed Mixing System products from G.H Industry, your key to achieving unmatched feed consistency and quality. We have used cutting-edge technology to develop a variety of solutions that redefine feed mixing precision as a leader in agricultural innovation.

    Our Feed Mixing System products have been painstakingly designed to combine feed ingredients with unmatched precision. Our systems enable equal nutrient distribution with configurable choices to fit different feed compositions, producing healthier and more productive animals.
    
    Use the Feed Mixing System from G.H Industry to learn the fine art of blending. Our solutions are created to optimize your feed production process, improve animal well-being, and ultimately contribute to your success whether you run a small farm or a large-scale enterprise.
    `}
    imgg={'https://images.unsplash.com/reserve/1hyDEofHQ0OX1mRnvhqw_IMG_1324.jpg?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'}
    products={productList}
    />
    </>
  )
}
