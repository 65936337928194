import React from 'react'
import { Header } from './Header'
import { Products } from './Products'
import { ProductSeries } from './ProductSeries'

import p1 from './Products/1p.jpg'
import p2 from './Products/2p.jpg'
import p3 from './Products/3p.jpg'
import p4 from './Products/4p.jpg'
import p5 from './Products/5p.jpg'
import p6 from './Products/6p.jpg'
import p7 from './Products/7p.jpg'

export const FeedCleaningSystem = () => {
  const productList = [
    {
      Pname: "Vibrating Screen Classifier",
      Pcap: "3-6T/H",
      Mpow: "1.5kw",
      img: p1,
    },
    {
      Pname: "Drum Pellet Pre-Cleaner",
      Pcap: "15-30T/H",
      Mpow: "0.75kw",
      img: p2,
    },
    {
      Pname: "Drum Powder Pre-Cleaner",
      Pcap: "10-15T/H",
      Mpow: "5.5kw",
      img: p3,
    },
    {
      Pname: "Pulse Dust Remover",
      Pcap: "",
      Mpow: "1.5kw",
      img: p4,
    },
    {
      Pname: "TBLM Series High Pressure Dust Collector",
      Pcap: "",
      Mpow: "11kw",
      img: p5,
    },
    {
      Pname: "Low Pressure Direct Jet Filter Dust Collector",
      Pcap: "",
      Mpow: "15kw",
      img: p6,
    },
    {
      Pname: "TCTX Series Permanent Magnet Sleeve",
      Pcap: "20-100TPH",
      Mpow: "",
      img: p7,
    }
    // Add more products here...
  ];
  return (
    <>
    <Header head={"Feed Equioments"}/>
    <ProductSeries />
    <Products

    heading={'Feed Cleaning System'}
    desc={`With the cutting-edge Feed Cleaning System products from G.H Industry, experience the peak of feed quality. We have developed a variety of solutions that redefine feed purity and quality as a leader in agricultural innovation.

    Our Feed Cleaning System products are proof of our constant dedication to the wellbeing and success of your cattle. We have created machinery that methodically sifts, separates, and purifies feed components using cutting-edge technology and precision engineering to ensure an ideal blend free of impurities and contaminants.
    
    Discover how our Feed Cleaning System will transform your business. Improve animal welfare, raise the caliber of the feed, and increase production efficiency. G.H. Industry offers more than just supplies; we also offer solutions that help your agricultural undertakings reach new heights.`}
    imgg={'https://images.unsplash.com/photo-1507662228758-08d030c4820b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80'}
    products={productList}
    />
    </>
  )
}
