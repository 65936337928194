import React, { useEffect, useState } from "react";

import "./Homepage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Aos from "aos";
import 'aos/dist/aos.css';
import {
  faFacebookF,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Section from "./Section";
import SectionForMobile from "./SectionForMobile"
import Faq from "./Faq";
import { Link } from "react-router-dom";
import Solution from "./Solution";
Aos.init();
const Homepage = () => {
  document.title="G.H Industry";

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 700);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  return (
   <>
    <main class="main">
      <section class="sectionnn banner banner-section" data-aos="fade-in" data-aos-delay="1000">
      
        <div class="container banner-column">
          <div class="banner-inner">
            <h2 class="heading-xl" data-aos="fade-up" data-aos-delay="1100">Manufacture & Fabricator in Pakistan</h2>
            <p class="paragraph" data-aos="fade-up" data-aos-delay="1300">
            This is Asghar Ali . (CEO) G.H Industry (Pvt.) LTD We are the best ever
manufacturer of Poultry / Cattle and Fish Feed Mill Plant's from 5 T/H to
30 Ton P/H and all kind of Machinery and Spare parts.
            </p>
            <Link to="/about" class="btn btn-darken btn-inline" data-aos="fade-up" data-aos-delay="1500">
              Read More
              <FontAwesomeIcon icon={faArrowRight} className="i" />
            </Link>
          </div>
          <div class="banner-links"data-aos="fade-left" data-aos-delay="1500">
            <a href="https://www.facebook.com/profile.php?id=100064021328744&mibextid=ZbWKwL" title="">
              <FontAwesomeIcon icon={faFacebookF} className="i" fade />
            </a>
            <a href="https://api.whatsapp.com/send?phone=9203016367000&text=Hi there! I'm from G.H Industry's website 🌐🏭 Just wanted to let you know that you can easily reach out to us on WhatsApp too. Feel free to tap the button below to start a chat. Looking forward to assisting you! 💬📞 #GHIndustry" title="">
              <FontAwesomeIcon icon={faWhatsapp} className="i" fade />
            </a>
            
            <a href="https://youtube.com/@GHindustries" title="">
              <FontAwesomeIcon icon={faYoutube} className="i" fade />
            </a>
          </div>
        </div>
      </section>
      
    </main>

    <section class="about-section-123">
  <div class="container-123">
    <div class="row clearfix">

     
      <div class="content-column-123 col-md-6 col-sm-12 col-xs-12">
        <div class="inner-column-123">
          <div class="sec-title-123">
            <div class="title-123">About Us</div>
            <h2>Innovating solutions, transforming <br/> Ideas into reality</h2>
          </div>
          <div class="text-123">A reputable and well-known Manufacturer and Fabricator Company in Pakistan is G.H Industry (Pvt.) LTD. We meet the demands of a variety of industries while upholding the highest standards of excellence by placing a significant emphasis on quality and innovation. We are able to provide our cherished customers with exceptional products and services thanks to our cutting-edge facilities and knowledgeable workforce. We consistently try to have a substantial impact on Pakistan's industrial sector while keeping ethics and professionalism.</div>
          <div class="email-123">Mail: <span class="theme-color-123">Gh.industry.pk@gmail.com</span></div>
          <a href="/about" class="theme-btn-123 btn-style-three-123">Read More</a>
        </div>
      </div>

   
      <div class="image-column-123 col-md-6 col-sm-12 col-xs-12">
        <div class="inner-column-123" data-wow-delay="0ms" data-wow-duration="1500ms">
          <div class="image-123">
            <img src="https://i.ibb.co/jb7rDWY/mock.png" alt=""/>
            <div class="overlay-box-123">
              <div class="year-box-123"><span class="number-123">38</span>Years <br/> Experience <br/> Working</div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
    {isMobile ? <SectionForMobile /> : <Section />}



    <Solution />
    <Faq />



    </>
  );
};

export default Homepage;
