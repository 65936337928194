import React, { useRef , useEffect} from 'react';
import { Header } from './Header'
import emailjs from '@emailjs/browser';
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faContactBook, faEnvelope, faGears, faHome, faMoneyBill, faMoneyBills, faMoneyCheck, faPhone, faTrainSubway } from '@fortawesome/free-solid-svg-icons';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';

export const Contact = () => {
    const form = useRef();
    useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
    }, []);
    document.title="G.H - Contact";
    
    const sendEmail = (e) => {
      e.preventDefault();
    
      emailjs.sendForm('service_4kbhaxe', 'template_lccomwu', form.current, 'fw0b2vik-v5Xv5mfd')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
        let text = document.getElementById('text-form')
        text.innerHTML = "Message Submited";
    };
  return (

    <>
    <Header head={'Contact Us'}/>



    <div class="feat bg-gray pt-5 pb-5">
  <div class="container">
    <div class="row">
      <div class="section-head col-sm-12">
        <h4><span>Why You</span> Contact Us?</h4>
        <p>Our commitment to providing excellent customer service goes beyond just talking about it; it is a firm commitment supported by concrete deeds. We at Company G.H Industry have painstakingly created a comprehensive, methodical, and consistently organized service assurance system. <br/>Every service request is rapidly and accurately handled thanks to this strong infrastructure. The reason you should choose to work with us is because we are steadfastly committed to giving you a flawless and effective customer experience.</p>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_one"><FontAwesomeIcon icon={faCartShopping}/></span>
          <h6>Do you have concerns about purchase?</h6>
          <p>We carefully consider your unique requirements when configuring the right equipment model and processing method for you. We also patiently address your inquiries.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_two"><FontAwesomeIcon icon={faGears}/></span>
          <h6>How to buy parts?</h6>
          <p>G.H. offers a comprehensive accessories system to offer you dependable and quick accessories services, full models, and premium and long-lasting accessories.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_three"><FontAwesomeIcon icon={faSalesforce}/></span>
          <h6>Worried about no guarantee after sale?</h6>
          <p>signing a post-sale service contract with you to offer you a full range of services, including installation, commissioning, training, maintenance, and repair.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_four"><FontAwesomeIcon icon={faTrainSubway}/></span>
          <h6>Worry about transportation?</h6>
          <p>G.H.'s expert packaging and modular transportation strategy will ensure a secure and efficient delivery.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_five"><FontAwesomeIcon icon={faMoneyBills}/></span>
          <h6>Cannot install and operate?</h6>
          <p>G.H's installation engineers can offer on-site assistance with infrastructure construction based on customers' demands.</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="item"> <span class="icon feature_box_col_six"><FontAwesomeIcon icon={faContactBook}/></span>
          <h6>Contact US</h6>
          <p>Our dedication to customer service is more than just a catchphrase. To assure prompt and appropriate handling of each service item.</p>
        </div>
      </div>
    </div>
  </div>
</div>


<div className='body'>
<section className='sectioon'>
    
    <div class="section-header">
      <div class="container">
        <h2>Contact Us</h2>
        <p>Our commitment to providing excellent customer service goes beyond just talking about it; it is a firm commitment supported by concrete deeds. We at Company G.H Industry have painstakingly created a comprehensive, methodical, and consistently organized service assurance system. Every service request is rapidly and accurately handled thanks to this strong infrastructure. The reason you should choose to work with us is because we are steadfastly committed to giving you a flawless and effective customer experience</p>
      </div>
    </div>
    
    <div class="container">
      <div class="row">
        
        <div class="contact-info">
          <div class="contact-info-item">
            <div class="contact-info-icon">
            <FontAwesomeIcon icon={faHome} className='x'/>
            </div>
            
            <div class="contact-info-content">
              <h4>Address</h4>
              <p>P.O Climax Octri Post Samanabad <br/>Gujranwala</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
            <FontAwesomeIcon icon={faPhone} className='x'/>
            </div>
            
            <div class="contact-info-content">
              <h4>Phone</h4>
              <p>0092-300-7448688 <br/> 0092-300-6317098</p>
            </div>
          </div>
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
            <FontAwesomeIcon icon={faEnvelope} className='x'/>
            </div>
            
            <div class="contact-info-content">
              <h4>Email</h4>
             <p>info@ghindustry.net</p>
            </div>
          </div>
        </div>
        
        <div class="contact-form">
          <form action="" id="contact-form" ref={form} onSubmit={sendEmail}>
            <h2>Send Message</h2>
            <div class="input-box">
              <input type="text" required="true" name="user_name"/>
              <span>Full Name</span>
            </div>
            
            <div class="input-box">
              <input type="email" required="true" name="user_email"/>
              <span>Email</span>
            </div>

            <div class="input-box">
              <input type="text" required="true" name="user_tel"/>
              <span>Tel/WhatsApp</span>
            </div>

            <div class="input-box">
              <input type="text" required="true" name="user_country"/>
              <span>Country</span>
            </div>
            
            <div class="input-box">
              <textarea required="true" name="user_msg"></textarea>
              <span>Type your Message...</span>
            </div>
            
            <div class="input-box">
                <h6 id='text-form' className='h66'></h6>
              <input type="submit" value="Send" name=""/>
            </div>
          </form>
        </div>
        
      </div>
    </div>
  </section>
  </div>

  <div class="iframe-container">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3376.1272031525364!2d74.1668071!3d32.2008029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391f297b7bb5089b%3A0xe478edcdba153a7f!2sGH%20Industry!5e0!3m2!1sen!2s!4v1692613354895!5m2!1sen!2s" width="600" height="450" frameborder="0"  allowfullscreen></iframe>
</div>


</>
    
  )
}
