import React from 'react';
import './Header.css';
import bgg from './Images/logobg2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

export const Header = (props) => {
  return (
    <header class="header" data-aos="slide-left" data-aos-delay="1000">
        <div class="overlay"></div>
        <img src={bgg} alt="Background Image" class="bg-image"/>
        <div class="header-content">
            <h1 className='fa-fade'>{props.head}</h1>
        <FontAwesomeIcon icon={faArrowDown} className="iii" bounce />
        </div>
    </header>
  )
}
